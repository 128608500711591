import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body, html, #root {
    min-height: 100vh;
  }
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    font-family: 'Izmir';
    -webkit-font-smoothing: antialiased;
  }
  button {
    border: 0;
    background: none;
    padding: 0;
    font-family: 'Izmir';
    outline: none;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }

  .active {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .active_dark {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
`
export default GlobalStyle
