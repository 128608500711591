import React from 'react'
import {
  BrowserRouter as RouterReact,
  Switch,
  Route,
} from "react-router-dom";
import Index from "../components/Index";
import Team  from "../components/Team";
import Partners  from "../components/Partners";
import Documents from "../components/Documents";
import Products from "../components/Products";
import Contacts from "../components/Contacts";
import Reasons from "../components/Reasons";

export default function Router() {
  return (
    <RouterReact>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route exact path="/team">
          <Team />
        </Route>
        <Route exact path="/partners">
          <Partners />
        </Route>
        <Route exact path="/documents">
          <Documents />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/contacts">
          <Contacts />
        </Route>
        <Route exact path="/reasons">
          <Reasons />
        </Route>
      </Switch>
    </RouterReact>
  )
}
