import tournamentsImage from '../img/product/tournaments.svg'
import predictionImage from '../img/product/predection.svg'
import marketImage from '../img/product/market.svg'
import mlmImage from '../img/product/mlm.svg'

export default [
  {
    slug: 'tournaments',
    name: 'Tournaments As a Service',
    description: 'Colizeum Tournament as a Service is a set of smart contracts SDKs, and graphical interfaces enabling anyone to create and monetize tournaments for games present in Colizeum Game Store.',
    color: '#FFCA6A',
    image: tournamentsImage,
  },
  {
    slug: 'prediction-markets',
    name: 'Prediction markets',
    description: 'Built atop of the Colizeum Tournaments as a Service core, Prediction Markets are a direct extension of it enabling anyone to bid on the outcome of a tournament or more subtle in-game events.',
    color: '#64E8FA',
    image: predictionImage,
  },
  {
    slug: 'attention-marketplace',
    name: 'Attention marketplace',
    description: 'The core of the Colizeum ecosystem, the in-store and in-app attention marketplace is a tokenized vehicle for direct monetization of Gamers attention. All on-chain, transparent, automated and permissionless.',
    color: '#8ED755',
    image: marketImage,
  },
  {
    slug: 'mlm',
    name: 'MLM As a Service',
    description: 'Colizeum MLM as a Service is a standard MLM tech with several twists: it is on-chain, thus transparent, it’s referal tree depth is a function of ZEUM stake, while the payouts are automated and are only controled by the user herself.',
    color: '#DF6DEF',
    image: mlmImage,
  },
]
