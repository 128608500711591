import React from "react";

const Comino: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="169" height="74" viewBox="0 0 169 74" fill="none">
    <path d="M36.5756 63.1105H36.0678C35.7494 63.1203 35.4307 63.1021 35.1155 63.0561C30.16 62.7825 25.3897 61.0795 21.3813 58.1528C17.3729 55.2261 14.298 51.2011 12.5283 46.5642C10.7585 41.9274 10.3697 36.8772 11.4087 32.024C12.4477 27.1709 14.8702 22.7226 18.3833 19.2168L12.6608 13.467C7.92176 18.1941 4.69339 24.2221 3.38512 30.7866C2.07686 37.351 2.74766 44.1561 5.31244 50.3388C7.87722 56.5215 12.2204 61.8031 17.7912 65.5139C23.362 69.2247 29.9093 71.1974 36.6028 71.1819V63.1105H36.5756Z" fill="white"/>
    <path d="M60.3185 61.5963C56.3616 65.5034 51.5034 68.3759 46.1731 69.9602C40.8427 71.5444 35.2042 71.7916 29.7557 70.68C24.3071 69.5683 19.2162 67.1319 14.9326 63.5861C10.6489 60.0403 7.30438 55.4941 5.1944 50.3492C3.08442 45.2042 2.27391 39.6188 2.83448 34.0864C3.39505 28.5539 5.30944 23.2446 8.40855 18.6275C11.5077 14.0103 15.6961 10.2274 20.6039 7.61291C25.5118 4.9984 30.988 3.63274 36.5488 3.63656C45.4232 3.64157 53.9476 7.09883 60.3185 13.2769C61.2254 12.9685 62.2502 12.6602 63.2568 12.37C59.1067 7.94909 53.9227 4.62911 48.1712 2.70861C42.4197 0.788114 36.281 0.32733 30.3073 1.3677C24.3335 2.40808 18.712 4.91698 13.9484 8.66876C9.18476 12.4205 5.42838 17.2976 3.01707 22.8612C0.605765 28.4248 -0.384834 34.5005 0.134366 40.5419C0.653565 46.5833 2.66628 52.4009 5.99149 57.4715C9.3167 62.5421 13.8501 66.7067 19.184 69.5907C24.518 72.4747 30.4851 73.9877 36.5488 73.9935C41.5505 73.99 46.4989 72.966 51.0913 70.984C55.6836 69.0021 59.8232 66.1039 63.2568 62.4669C62.2502 62.213 61.2435 61.9046 60.3185 61.56" fill="white"/>
    <path d="M50.8137 55.5194C47.42 58.189 43.3435 59.8497 39.0505 60.3117C34.7574 60.7737 30.4209 60.0183 26.5369 58.1319C22.6529 56.2455 19.3781 53.3042 17.0869 49.6444C14.7957 45.9845 13.5807 41.7537 13.5807 37.4359C13.5807 33.118 14.7957 28.8872 17.0869 25.2273C19.3781 21.5675 22.6529 18.6262 26.5369 16.7398C30.4209 14.8534 34.7574 14.098 39.0505 14.56C43.3435 15.022 47.42 16.6827 50.8137 19.3523C51.4518 18.702 52.1336 18.096 52.8542 17.5386C49.0836 14.4767 44.5225 12.5459 39.6996 11.9701C34.8766 11.3942 29.9891 12.1969 25.6037 14.285C21.2182 16.3731 17.5141 19.6612 14.9209 23.7682C12.3276 27.8752 10.9512 32.633 10.9512 37.4903C10.9512 42.3475 12.3276 47.1053 14.9209 51.2123C17.5141 55.3193 21.2182 58.6074 25.6037 60.6955C29.9891 62.7836 34.8766 63.5863 39.6996 63.0104C44.5225 62.4346 49.0836 60.5039 52.8542 57.442C52.1287 56.8253 51.4848 56.2086 50.8137 55.5647" fill="white"/>
    <path d="M44.0575 43.2946C42.8394 44.8435 41.1687 45.9741 39.278 46.5293C37.3873 47.0844 35.3706 47.0363 33.5084 46.3918C31.6463 45.7473 30.0314 44.5384 28.8884 42.9332C27.7454 41.3281 27.1311 39.4066 27.1311 37.436C27.1311 35.4655 27.7454 33.544 28.8884 31.9389C30.0314 30.3337 31.6463 29.1248 33.5084 28.4802C35.3706 27.8357 37.3873 27.7877 39.278 28.3428C41.1687 28.8979 42.8394 30.0286 44.0575 31.5775C44.3075 30.5618 44.6102 29.5598 44.9644 28.5757C43.5224 27.1957 41.7636 26.191 39.8425 25.6498C37.9215 25.1086 35.8968 25.0474 33.9466 25.4716C31.9963 25.8959 30.1801 26.7925 28.6574 28.0828C27.1347 29.3732 25.9522 31.0177 25.2137 32.8719C24.4752 34.7261 24.2033 36.7333 24.422 38.7172C24.6407 40.7011 25.3432 42.6009 26.4678 44.2497C27.5924 45.8986 29.1047 47.2461 30.8719 48.1738C32.639 49.1015 34.607 49.581 36.6028 49.5703C39.7188 49.5998 42.724 48.416 44.9825 46.2692C44.6233 45.2959 44.3205 44.3027 44.0757 43.2946" fill="white"/>
    <path d="M108.502 25.9463H111.186V48.927H108.502V25.9463Z" fill="white"/>
    <path d="M60.9899 25.2749C58.5869 25.2857 56.241 26.0081 54.2482 27.3509C52.2554 28.6937 50.7051 30.5968 49.793 32.82C48.8809 35.0431 48.6478 37.4867 49.1232 39.8422C49.5985 42.1977 50.7611 44.3595 52.464 46.0549C54.167 47.7503 56.334 48.9032 58.6916 49.368C61.0492 49.8329 63.4917 49.5889 65.7108 48.6668C67.9298 47.7448 69.826 46.186 71.1599 44.1873C72.4938 42.1885 73.2057 39.8394 73.2058 37.4364C73.1962 34.2045 71.9043 31.1085 69.6139 28.8283C67.3235 26.5481 64.2218 25.2701 60.9899 25.2749ZM60.9899 27.9956C62.8632 27.9974 64.694 28.5549 66.2504 29.5976C67.8067 30.6402 69.0188 32.1212 69.7333 33.8529C70.4477 35.5847 70.6323 37.4895 70.2637 39.3263C69.8952 41.163 68.99 42.8492 67.6628 44.1713C66.3356 45.4934 64.646 46.3921 62.8079 46.7536C60.9697 47.1151 59.0656 46.9232 57.3366 46.2022C55.6075 45.4811 54.1313 44.2634 53.0946 42.703C52.0579 41.1426 51.5074 39.3097 51.5128 37.4364C51.52 34.9251 52.5208 32.5188 54.2965 30.743C56.0723 28.9673 58.4786 27.9665 60.9899 27.9593" fill="white"/>
    <path d="M135.6 25.9463V44.6102L120.718 25.9463H117.97V48.927H120.718V30.2631L135.6 48.927H138.32V25.9463H135.6Z" fill="white"/>
    <path d="M98.9972 25.9463L90.1913 39.1779L81.34 25.9463H78.6465V48.927H81.34V30.2631L90.1913 43.5491L98.9972 30.2631V48.927H101.691V25.9463H98.9972Z" fill="white"/>
    <path d="M155.95 27.9592C157.821 27.9645 159.649 28.5242 161.202 29.5674C162.755 30.6106 163.965 32.0906 164.677 33.8206C165.39 35.5506 165.574 37.453 165.206 39.2876C164.839 41.1221 163.936 42.8066 162.612 44.1284C161.287 45.4502 159.601 46.3499 157.766 46.7141C155.93 47.0782 154.028 46.8904 152.3 46.1744C150.571 45.4584 149.093 44.2463 148.053 42.6911C147.013 41.1358 146.457 39.3073 146.455 37.4362C146.462 34.9218 147.466 32.5127 149.245 30.7365C151.025 28.9602 153.436 27.9616 155.95 27.9592ZM155.95 49.634C158.355 49.625 160.704 48.9036 162.699 47.5609C164.695 46.2181 166.248 44.3142 167.162 42.0896C168.076 39.8649 168.311 37.4193 167.836 35.0614C167.361 32.7035 166.199 30.5392 164.495 28.8417C162.791 27.1441 160.622 25.9895 158.263 25.5235C155.903 25.0576 153.458 25.3012 151.237 26.2237C149.016 27.1462 147.118 28.7061 145.782 30.7066C144.447 32.707 143.734 35.0583 143.734 37.4634C143.744 40.6962 145.036 43.7931 147.326 46.0747C149.616 48.3564 152.718 49.6364 155.95 49.634Z" fill="white"/>
  </svg>
)

export default Comino
