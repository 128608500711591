import React from "react";
import styled from 'styled-components'

interface Props {
  dark?: boolean
}

const Footer: React.FunctionComponent<Props> = props => (
    <Root>
      <Line>
        <Copyright dark={props.dark} >
          © Colizeum, All rights reserved, 2021
        </Copyright>
      </Line>
    </Root>
  )

export default Footer

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 27px 0 45px 0;
  ${props => props.theme.adaptive.index.xl} {
    padding: 24px 0 39px 0;
  }
  ${props => props.theme.adaptive.index.lg} {
    padding: 24px 0 34px 0;
  }
  ${props => props.theme.adaptive.index.md} {
    padding: 39px 0 30px 0;
  }
  ${props => props.theme.adaptive.index.smd} {
    padding: 18px 0 22px 0;
  }
  @media screen and (max-width: 800px) {
    padding: 14px 0 14px 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: center;
`

const Copyright = styled.div<Props>`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.05em;
  color:${({ dark }) => dark ? '#CCCCCC' : '#676767'};
  opacity: 0.8;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 10px;
    line-height: 13px;
  }
`
