import GlobalFonts from './theme/fonts';
import GlobalStyle from './theme/globalStyle';
import Router from './pages/Router'
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme'
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <GlobalFonts />
        <GlobalStyle />
        <Router />
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
