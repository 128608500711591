import React from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import togetherImage from '../../img/partners/together.jpg'
import partnersList from "../../store/partners";

export default function Partners() {
  return (
    <Root>
      <PageWrapper dark={true}>
        <Container>
          <Title>
            Partners
          </Title>
          <SubTitle>
            Product is built by the team with <Count>$1 000 000 000</Count><Plus>+</Plus> in exits value
          </SubTitle>
          <List>
            {partnersList.map((partner, idx) => {
              return (
                <Partner href={partner.link} target={'_blank'}>
                  {partner.getIcon()}
                </Partner>
              )
            })}
          </List>
        </Container>
      </PageWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #151515;
  &:before {
    content: '';
    position: absolute;
    width: 2116px;
    height: 2055px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%)  rotate(-10.15deg);
    opacity: 0.05;
    background: url("${togetherImage}") center no-repeat;
    background-size: cover;
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
  @media screen and (max-width: 800px) {
    background: transparent;
  }
`

const Container = styled.div`
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 1060px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 920px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1000px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 870px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 575px;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #FFFFFF;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
    margin-bottom: 11px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 7px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 9px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 6px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 5px;
  }
`

const SubTitle = styled.div`
  width: 100%;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.045em;
  color: #FFFFFF;
  margin-bottom: 48px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 41px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 34px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
  }
`

const Plus = styled.span`
  color: #FFCA6A;
  font-weight: bold;
`

const Count = styled.span`
  font-weight: bold;
`

const List = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const Partner = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 73px;
  margin-right: 95px;
  margin-top: 80px;
  ${props => props.theme.adaptive.index.xl} {
    width: 193px;
    height: 64px;
    margin-right: 82px;
    margin-top: 75px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 165px;
    height: 55px;
    margin-right: 70px;
    margin-top: 60px
  }
  ${props => props.theme.adaptive.index.md} {
    width: 180px;
    height: 59px;
    margin-right: 76px;
    margin-top: 65px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 156px;
    height: 52px;
    margin-right: 65px;
    margin-top: 56px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 145px;
    height: 48px;
    margin-right: 50px;
    margin-top: 40px;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
  &:nth-child(4n+4) {
    margin-right: 0;
    ${props => props.theme.adaptive.index.sm} {
      margin-right: 50px;
    }
  }
  svg {
    display: block;
    max-width: 220px;
    max-height: 73px;
    ${props => props.theme.adaptive.index.xl} {
      max-width: 193px;
      max-height: 64px;
    }
    ${props => props.theme.adaptive.index.lg} {
      max-width: 165px;
      max-height: 55px;
    }
    ${props => props.theme.adaptive.index.md} {
      max-width: 180px;
      max-height: 59px;
    }
    ${props => props.theme.adaptive.index.smd} {
      max-width: 156px;
      max-height: 52px;
    }
    ${props => props.theme.adaptive.index.sm} {
      max-width: 145px;
      max-height: 48px;
    }
  }
  img {
    display: block;
    width: 100%;
  }
`
