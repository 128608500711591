import { createGlobalStyle } from 'styled-components'
import IzmirWoff2Bold from './fonts/Izmir-Bold.woff2'
import IzmirWoffBold from './fonts/Izmir-Bold.woff'
import IzmirWoff2Extralight from './fonts/Izmir-Extralight.woff2'
import IzmirWoffExtralight from './fonts/Izmir-Extralight.woff'
import IzmirWoff2Light from './fonts/Izmir-Light.woff2'
import IzmirWoffLight from './fonts/Izmir-Light.woff'
import IzmirWoff2Regular from './fonts/Izmir-Regular.woff'
import IzmirWoffRegular from './fonts/Izmir-Regular.woff2'
import IzmirWoff2Medium from './fonts/Izmir-Medium.woff2'
import IzmirWoffMedium from './fonts/Izmir-Medium.woff'
import CeraProWoffBold from './fonts/CeraPro/CeraPro-Bold.woff'
import CeraProWoff2Bold from './fonts/CeraPro/CeraPro-Bold.woff2'

// Izmir
const Izmir = {
  extralight: {
    woff2: IzmirWoff2Extralight,
    woff: IzmirWoffExtralight,
  },
  light: {
    woff2: IzmirWoff2Light,
    woff: IzmirWoffLight,
  },
  regular: {
    woff2: IzmirWoff2Regular,
    woff: IzmirWoffRegular,
  },
  medium: {
    woff2: IzmirWoff2Medium,
    woff: IzmirWoffMedium,
  },
  bold: {
    woff2: IzmirWoff2Bold,
    woff: IzmirWoffBold,
  },
}

// CeraPro
const ceraPro = {
  bold: {
    woff2: CeraProWoff2Bold,
    woff: CeraProWoffBold,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Izmir';
    src: local('Izmir'),
      url(${Izmir.extralight.woff2}) format('woff2'),
      url(${Izmir.extralight.woff}) format('woff');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Izmir';
    src: local('Izmir'),
      url(${Izmir.light.woff2}) format('woff2'),
      url(${Izmir.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Izmir';
    src: local('Izmir'),
      url(${Izmir.regular.woff2}) format('woff2'),
      url(${Izmir.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Izmir';
    src: local('Izmir'),
      url(${Izmir.bold.woff2}) format('woff2'),
      url(${Izmir.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Izmir';
    src: local('Izmir'),
    url(${Izmir.medium.woff2}) format('woff2'),
    url(${Izmir.medium.woff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'CeraPro';
    src: local('CeraPro'),
         url(${ceraPro.bold.woff2}) format('woff2'),
         url(${ceraPro.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
`
export default Fonts
