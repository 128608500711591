import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ModalTemplate from './ModalTemplate'
import { ModalProps } from './ModalTemplate'
import parse from 'html-react-parser';
import arrow from 'img/arrow.svg'
import { noop } from 'lodash';

interface ContentProps {
  title: string
  iconPath?: string
  image?: string | string[]
  tabs?: string[]
  text?: string[]
  steps?: any
  shortDesc?: string
}

type ModalType = "attention" | "tournaments" | "prediction-markets" | 'mlm';

export interface Props extends ModalProps {
  tabs?: boolean
  content?: ContentProps
  type?: ModalType
  onNext?(): void
}

function Product(props: Props) {
  const {
    tabs=false,
    content=null,
    active=false,
    onClose=noop,
    onNext=noop,
    type,
  } = props

  const [activeTab, setActiveTab] = useState(0)

  const getImage = () => {
    if (typeof content?.image === 'string') {
      return (
        <ImageWrapper modalType={type}>
          <ContentImage>
            <Image src={content?.image} />
          </ContentImage>
        </ImageWrapper>
      )
    }
    else if (typeof content?.image === 'object') {
      return content?.image.map((image) => {
        return (
          <ImageWrapper modalType={type}>
            <ContentImage>
              <Image src={image} />
            </ContentImage>
          </ImageWrapper>
        )
      })
    }
  }

  return (
    <ModalTemplate
      active={active}
      onClose={onClose}
    >
      <ProductRoot>
        <ModalHeader>
          <TitleWrapper>
            <Title>{content?.title}</Title>
            {content?.iconPath &&
              <Icon iconPath={content.iconPath} />
            }
          </TitleWrapper>
          {content?.shortDesc &&
            <ShortDesc>
              {content?.shortDesc}
            </ShortDesc>
          }
          {tabs &&
            <TabWrapper>
              {content?.tabs?.map((tab, idx) => {
                return (
                  <Tab
                    key={idx}
                    onClick={() => setActiveTab(idx)}
                    active={activeTab === idx}
                  >
                    {parse(tab)}
                  </Tab>
                )
              })}
            </TabWrapper>
          }
        </ModalHeader>
        <Content>
          {content?.image &&
            getImage()
          }
          <ContentText>
            {content?.text &&
              <ContentDesc>
                {content?.text[activeTab]}
              </ContentDesc>
            }
            {tabs ? (
                content?.steps[activeTab].map((step: string, idx: number) => {
                  console.log(step)
                  return (
                    <Step
                      key={idx}
                      modalType={type}
                    >
                      <StepNumber>{idx+1}</StepNumber>
                      <StepText>{parse(step)}</StepText>
                    </Step>
                  )
                })
            ) : (
              content?.steps?.map((step: string, idx: number) => {
                return (
                  <Step
                    key={idx}
                    modalType={type}
                  >
                    <StepNumber>{idx+1}</StepNumber>
                    <StepText>{parse(step)}</StepText>
                  </Step>
                )
              })
            )}
          </ContentText>
        </Content>
        <NextButton
          onClick={() => {
            onClose()
            onNext()
          }}
        >
          Next Product
        </NextButton>
      </ProductRoot>
    </ModalTemplate>
  )
}

export default Product


interface TypeModalProps {
  modalType?: string
}

interface TabProps {
  active: boolean
}

const TabWrapper = styled.div`
  margin-left: 16px;
  ${props => props.theme.adaptive.modals.xl} {
    margin-left: 19px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    margin-left: 16px;
  }
  ${props => props.theme.adaptive.modals.md} {
    margin-left: 10px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 100%;
    margin-top: 12px;
    margin-left: -12px;
  }
`

const Tab = styled.button<TabProps>`
  position: relative;
  font-size: 36px;
  letter-spacing: -0.05em;
  color: #5C5E5E;
  margin: 0 23px;
  ${props => props.theme.adaptive.modals.xl} {
    font-size: 32px;
    margin: 0 17px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    font-size: 27px;
    margin: 0 15px;
  }
  ${props => props.theme.adaptive.modals.md} {
    font-size: 23px;
    margin: 0 12px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    font-size: 16px;
    margin: 0 12px;
  }
  &:before {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    background: #DF6DEF;
    width: 100%;
    height: 1px;
  }
  ${({ active }) => active && css`
    color: #fff;
    &:before {
      display: block;
    }
  `}
`

const StepText = styled.div`
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.05em;
  font-weight: 300;
  color: #fff;
  ${props => props.theme.adaptive.modals.xl} {
    font-size: 17px;
    line-height: 23px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.modals.md} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    font-size: 13px;
    line-height: 17px;
  }
  strong {
    font-weight: 600;
  }
  p {
    margin: 0;
    margin-bottom: 15px;
    ${props => props.theme.adaptive.modals.xl} {
      margin-bottom: 11px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      margin-bottom: 9px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      margin-bottom: 4px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    display: block;
    width: 100%;
    ${props => props.theme.adaptive.modals.sm} {
      position: absolute;
      width: 301px;
      left: 31px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      ${props => props.theme.adaptive.modals.xl} {
        font-size: 17px;
        line-height: 23px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        font-size: 14px;
        line-height: 19px;
      }
      ${props => props.theme.adaptive.modals.md} {
        font-size: 13px;
        line-height: 17px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
`

const ShortDesc = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  max-width: 715px;
  margin: 0;
  margin-left: 41px;
  ${props => props.theme.adaptive.modals.xl} {
    font-size: 20px;
    line-height: 27px;
    margin-left: 37px;
    max-width: 626px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    font-size: 17px;
    line-height: 23px;
    margin-left: 32px;
    max-width: 537px;
  }
  ${props => props.theme.adaptive.modals.md} {
    font-size: 15px;
    line-height: 20px;
    margin-left: 28px;
    max-width: 462px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    font-size: 13px;
    line-height: 17px;
    margin-left: 0;
    max-width: unset;
    width: 100%;
    margin-top: 13px;
  }
`

const attentionMarketplaceStepStyles = css`
  ${StepText} {
    font-size: 17px;
    line-height: 23px;
    ${props => props.theme.adaptive.modals.xl} {
      font-size: 16px;
      line-height: 21px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      font-size: 14px;
      line-height: 19px;
    }
    ${props => props.theme.adaptive.modals.md} {
      font-size: 13px;
      line-height: 17px;
    }
  }
`

const predictionStepStyles = css`
  ${StepText} {
    ${props => props.theme.adaptive.modals.sm} {
      margin-bottom: 28px;
    }
  }
`

const mlmStepStyles = css`
  ${StepText} {
    font-size: 21px;
    line-height: 28px;
    ${props => props.theme.adaptive.modals.xl} {
      font-size: 17px;
      line-height: 23px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      font-size: 14px;
      line-height: 19px;
    }
    ${props => props.theme.adaptive.modals.md} {
      font-size: 13px;
      line-height: 17px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      font-size: 12px;
      line-height: 16px;
    }
  }
`

const Step = styled.div<TypeModalProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  ${props => props.theme.adaptive.modals.xl} {
    margin-bottom: 20px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.modals.md} {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${({ modalType }) => modalType === 'attention' && attentionMarketplaceStepStyles}
  ${({ modalType }) => modalType === 'mlm' && mlmStepStyles}
  ${({ modalType }) => modalType === 'prediction-markets' && predictionStepStyles}
`

const StepNumber = styled.div`
  width: 48px;
  font-weight: 100;
  font-size: 64px;
  line-height: 54px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  opacity: 0.35;
  text-align: center;
  flex-shrink: 0;
  margin-right: 18px;
  ${props => props.theme.adaptive.modals.xl} {
    width: 42px;
    font-size: 56px;
    line-height: 44px;
    margin-right: 16px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    width: 36px;
    font-size: 48px;
    line-height: 39px;
    margin-right: 13px;
  }
  ${props => props.theme.adaptive.modals.md} {
    width: 24px;
    font-size: 41px;
    line-height: 36px;
    margin-right: 15px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 17px;
    font-size: 28px;
    line-height: 22px;
    margin-right: 10px;
  }
`

const ContentImage = styled.div`
  position: relative;
  border: 1px solid #4C4C4C;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
  padding: 20px 50px;
  ${props => props.theme.adaptive.modals.xl} {
    border-radius: 7px 7px 0 0;
    padding: 18px 43px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    border-radius: 6px 6px 0 0;
    padding: 17px 35px;
  }
  ${props => props.theme.adaptive.modals.md} {
    border-radius: 4px 4px 0 0;
    padding: 15px 32px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    border-radius: 3px 3px 0 0;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 200px;
    background: linear-gradient(to top, #181919 10%, #4C4C4C);
  }
  &:before {
    left: -1px;
  }
  &:after {
    right: -1px;
  }
`

const tournamentsImageStyles = css`
  ${ContentImage} {
    width: 581px;
    ${props => props.theme.adaptive.modals.xl} {
      width: 508px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      width: 436px;
    }
    ${props => props.theme.adaptive.modals.md} {
      width: 375px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      width: 247px;
    }
  }
`

const predictionmarketsImageStyles = css`
  ${ContentImage} {
    width: 925px;
    ${props => props.theme.adaptive.modals.xl} {
      width: 818px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      width: 701px;
    }
    ${props => props.theme.adaptive.modals.md} {
      width: 604px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      width: 419px;
    }
  }
`

const mlmImageStyles = css`
  ${ContentImage} {
    width: 679px;
    ${props => props.theme.adaptive.modals.xl} {
      width: 594px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      width: 509px;
    }
    ${props => props.theme.adaptive.modals.md} {
      width: 438px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      width: 301px;
    }
  }
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
`

const attentionMarketplaceImageStyles = css`
  padding-right: 37px;
  ${props => props.theme.adaptive.modals.xl} {
    padding-right: 35px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    padding-right: 23px;
  }
  ${props => props.theme.adaptive.modals.md} {
    padding-right: 28px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    padding-right: 28px;
  }
  ${ContentImage} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    ${props => props.theme.adaptive.modals.xl} {
      padding: 22px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      padding: 18px;
    }
    ${props => props.theme.adaptive.modals.md} {
      padding: 14px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      padding-right: 12px;
    }
  }
  &:first-child {
    ${Image} {
      ${props => props.theme.adaptive.modals.xl} {
        width: 441px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        width: 378px;
      }
      ${props => props.theme.adaptive.modals.md} {
        width: 292px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        width: 211px;
      }
    }
    ${ContentImage} {
      width: 492px;
      ${props => props.theme.adaptive.modals.xl} {
        width: 411px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        width: 352px;
      }
      ${props => props.theme.adaptive.modals.md} {
        width: 272px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        width: 197px;
      }
    }
  }
  &:nth-child(2) {
    ${Image} {
      ${props => props.theme.adaptive.modals.xl} {
        width: 348px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        width: 298px;
      }
      ${props => props.theme.adaptive.modals.md} {
        width: 230px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        width: 167px;
      }
    }
    position: relative;
    ${ContentImage} {
      width: 470px;
      ${props => props.theme.adaptive.modals.xl} {
        width: 393px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        width: 337px;
      }
      ${props => props.theme.adaptive.modals.md} {
        width: 260px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        width: 188px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: -130px;
      top: 80px;
      width: 131px;
      height: 1px;
      background: #4C4C4C;
      ${props => props.theme.adaptive.modals.xl} {
        left: -70px;
        top: 92px;
        width: 71px;
      }
      ${props => props.theme.adaptive.modals.lg} {
        left: -53px;
        top: 79px;
        width: 54px;
      }
      ${props => props.theme.adaptive.modals.md} {
        left: -53px;
        top: 79px;
        width: 54px;
      }
      ${props => props.theme.adaptive.modals.sm} {
        left: -44px;
        top: 44px;
        width: 43px;
      }
    }
  }
`

const ImageWrapper = styled.div<TypeModalProps>`
  padding-right: 60px;
  ${props => props.theme.adaptive.modals.xl} {
    padding-right: 55px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    padding-right: 50px;
  }
  ${props => props.theme.adaptive.modals.md} {
    padding-right: 45px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    padding-right: 38px;
    float: left;
  }
  ${({ modalType }) => modalType === 'tournaments' && tournamentsImageStyles}
  ${({ modalType }) => modalType === 'attention' && attentionMarketplaceImageStyles}
  ${({ modalType }) => modalType === 'tournaments' && tournamentsImageStyles}
  ${({ modalType }) => modalType === 'prediction-markets' && predictionmarketsImageStyles}
  ${({ modalType }) => modalType === 'mlm' && mlmImageStyles}
`

const Content = styled.div`
  display: flex;
  margin-top: 54px;
  ${props => props.theme.adaptive.modals.xl} {
    margin-top: 45px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    margin-top: 38px;
  }
  ${props => props.theme.adaptive.modals.md} {
    margin-top: 33px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    display: block;
    margin-top: 24px;
  }
`

const ContentText = styled.div`
`

const ContentDesc = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  margin-bottom: 39px;
  ${props => props.theme.adaptive.modals.xl} {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 33px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 28px;
  }
  ${props => props.theme.adaptive.modals.md} {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 24px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`


const ProductRoot = styled.div`
  height: 100%;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding-top: 45px;
  ${props => props.theme.adaptive.modals.xl} {
    padding-top: 37px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    padding-top: 32px;
  }
  ${props => props.theme.adaptive.modals.md} {
    padding-top: 27px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    flex-wrap: wrap;
    padding-top: 19px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface IconProps {
  readonly iconPath: string;
};

const Icon = styled.div<IconProps>`
  width: 50px;
  height: 50px;
  background-image: url(${({ iconPath }) => iconPath});
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 30px;
  ${props => props.theme.adaptive.modals.xl} {
    width: 45px;
    height: 45px;
    margin-left: 22px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    width: 40px;
    height: 40px;
    margin-left: 19px;
  }
  ${props => props.theme.adaptive.modals.md} {
    width: 35px;
    height: 35px;
    margin-left: 16px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 30px;
    height: 30px;
    margin-left: 16px;
  }
`

const Title = styled.h2`
  font-family: 'CeraPro';
  text-transform: uppercase;
  color: #fff;
  font-size: 60px;
  line-height: 38px;
  font-weight: 700;
  margin: 0;
  ${props => props.theme.adaptive.modals.xl} {
    font-size: 53px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    font-size: 45px;
  }
  ${props => props.theme.adaptive.modals.md} {
    font-size: 40px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    font-size: 40px;
  }
`

const NextButton = styled.button`
  position: absolute;
  bottom: 40px;
  right: 60px;
  font-size: 14px;
  line-height: 14px;
  color: #DF6DEF;
  cursor: pointer;
  padding-right: 10px;
  ${props => props.theme.adaptive.modals.sm} {
    bottom: 30px;
  }
  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 4px;
    width: 4px;
    height: 8px;
    background: url(${arrow}) no-repeat center;
  }
`
