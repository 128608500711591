import React from 'react'

export default {
  adaptive: {
    index: {
      xl: '@media screen and (max-width: 1750px), screen and (max-height: 950px)',
      lg: '@media screen and (max-width: 1500px), screen and (max-height: 874px)',
      md: '@media screen and (max-width: 1250px)',
      smd: '@media screen and (max-height: 799px)',
      sm: '@media screen and (max-width: 1100px)',
    },
    modals: {
      xl: '@media screen and (max-height: 860px), screen and (max-width: 1675px)',
      lg: '@media screen and (max-height: 760px), screen and (max-width: 1450px)',
      md: '@media screen and (max-height: 680px), screen and (max-width: 1250px)',
      sm: '@media screen and (max-height: 680px), screen and (max-width: 1100px)',
    }
  }
}
