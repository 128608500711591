import React from "react";
import styled from 'styled-components'
import { NavLink, Link  } from "react-router-dom";
import Logo from "../Icons/header/Logo";

interface Props {
  dark?: boolean
  index?: boolean
}

const Header: React.FunctionComponent<Props> = props => (
    <Root>
      <Line>
        <LogoWrap index={props.index}>
          <Link to={'/'}>
            <Logo index={props.index} dark={props.dark}/>
          </Link>
        </LogoWrap>
        <Links>
          <LinkWrap dark={props.dark}>
            <NavLink to="/team" activeClassName="active">Team</NavLink>
          </LinkWrap>
          <LinkWrap dark={props.dark}>
            <NavLink to="/partners" activeClassName="active_dark">Partners</NavLink>
          </LinkWrap>
          <LinkWrap dark={props.dark}>
            <NavLink to="/documents" activeClassName="active">Documents</NavLink>
          </LinkWrap>
          <LinkWrap dark={props.dark}>
            <NavLink to="/contacts" activeClassName="active">Contact Us</NavLink>
          </LinkWrap>
        </Links>
      </Line>
    </Root>
  )

export default Header

const Root = styled.div`
  width: 1636px;
  margin: 0 auto;
  padding: 33px 0 33px 0;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
    padding: 29px 0;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
    padding: 25px 0;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
    padding: 21px 0;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
    padding: 21px 0;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 686px;
    padding: 16px 0;
  }
`

const Line = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Links = styled.div`
  display: flex;
  justify-content: flex-start;
`

const LinkWrap = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 23px;
  transition: 0.4s;
  z-index: 10;
  ${props => props.theme.adaptive.index.xl} {
    margin-right: 29px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-right: 24px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-right: 16px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-right: 7px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-right: 5px;
  }
  a {
    display: block;
    padding: 14px 23px 18px;
    border: 1px solid transparent;
    border-radius: 50px;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.045em;
    color: ${({ dark }) => dark ? '#FFFFFF' : '#353636'};
    opacity: 0.8;
    transition: 0.4s;
    ${props => props.theme.adaptive.index.xl} {
      padding: 12px 23px 15px;
      font-size: 21px;
      line-height: 21px;
    }
    ${props => props.theme.adaptive.index.lg} {
      padding: 10px 16px 12px;
      font-size: 18px;
      line-height: 18px;
    }
    ${props => props.theme.adaptive.index.md} {
      padding: 9px 14px 12px;
      font-size: 16px;
      line-height: 16px;
    }
    ${props => props.theme.adaptive.index.smd} {
      padding: 9px 19px 10px;
      font-size: 15px;
      line-height: 15px;
    }
    ${props => props.theme.adaptive.index.sm} {
      padding: 7px 15px 8px;
      font-size: 14px;
      line-height: 14px;
    }
    &:hover {
      background: ${({ dark }) => dark ? 'transparent' : '#FFFFFF'};
      border: 1px solid ${({ dark }) => dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
    }
  }
  &:last-child {
    margin-right: 0;
  }

`

const LogoWrap = styled.div<Props>`
  a {
    position: relative;
    display: block;
    z-index: 10;
    outline: none;
    width: ${({ index }) => index ? '44px' : '205px'};
    height: 56px;
    ${props => props.theme.adaptive.index.xl} {
      height: 49px;
    }
    ${props => props.theme.adaptive.index.lg} {
      height: 42px;
    }
    ${props => props.theme.adaptive.index.md} {
      height: 37px;
    }
    ${props => props.theme.adaptive.index.smd} {
      height: 36px;
    }
    ${props => props.theme.adaptive.index.sm} {
      height: 32px;
    }
    svg {
      width: auto;
      height: 100%;
    }
  }
`
