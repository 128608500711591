import React from "react";

interface Props {
  dark?: boolean
  index?: boolean
}

const Logo: React.FunctionComponent<Props> = props => (
    <>
      {
        (props.index)
        ?  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="57" viewBox="0 0 44 57" fill="none">
            <path d="M37.388 13.3309L33.5572 19.7742H21.7754L17.9446 13.3386L13.5684 20.7549L27.6655 45.5521L41.7627 20.7534L37.388 13.3309ZM26.714 36.0856L20.9219 22.7883L26.714 28.5543V36.0856ZM28.6554 36.0856V28.5512L34.4475 22.7852L28.6554 36.0856Z" fill="#898989"/>
            <path d="M37.388 13.3309L33.5572 19.7742H21.7754L17.9446 13.3386L13.5684 20.7549L27.6655 45.5521L41.7627 20.7534L37.388 13.3309ZM26.714 36.0856L20.9219 22.7883L26.714 28.5543V36.0856ZM28.6554 36.0856V28.5512L34.4475 22.7852L28.6554 36.0856Z" fill="url(#index_paint0_linear)"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="#898989"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="url(#index_paint1_linear)"/>
            <defs>
              <linearGradient id="index_paint0_linear" x1="27.6655" y1="13.3309" x2="27.6655" y2="45.5521" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="index_paint1_linear" x1="22" y1="0" x2="22" y2="56.1405" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
            </defs>
          </svg>
        : (props.dark)
          ? <svg xmlns="http://www.w3.org/2000/svg" width="205" height="57" viewBox="0 0 205 57" fill="none">
            <path d="M37.388 13.3308L33.5572 19.7741H21.7754L17.9446 13.3385L13.5684 20.7548L27.6655 45.552L41.7627 20.7533L37.388 13.3308ZM26.714 36.0855L20.9219 22.7882L26.714 28.5542V36.0855ZM28.6554 36.0855V28.5511L34.4475 22.7851L28.6554 36.0855Z" fill="white"/>
            <path d="M37.388 13.3308L33.5572 19.7741H21.7754L17.9446 13.3385L13.5684 20.7548L27.6655 45.552L41.7627 20.7533L37.388 13.3308ZM26.714 36.0855L20.9219 22.7882L26.714 28.5542V36.0855ZM28.6554 36.0855V28.5511L34.4475 22.7851L28.6554 36.0855Z" fill="url(#dark_paint0_linear)"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="white"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="url(#dark_paint1_linear)"/>
            <path d="M69 39.6C67.6047 40.3535 65.7565 41 63.2443 41C59.1639 41 56.6869 39.5653 54.7331 37.6635C52.0465 35.0097 51 32.0334 51 28.5176C51 24.179 52.7442 21.058 54.7331 19.1577C57.0709 16.8972 59.9656 16.0006 63.2795 16.0006C65.2772 15.9806 67.2466 16.487 69 17.4714V22.9237C68.305 22.089 67.4441 21.417 66.4753 20.953C65.5065 20.489 64.4522 20.2437 63.3835 20.2337C58.7095 20.2337 55.6756 24.179 55.6756 28.5899C55.6756 33.8975 59.6535 36.77 63.5579 36.77C64.5965 36.774 65.623 36.5417 66.5639 36.0897C67.5048 35.6377 68.3367 34.9772 69 34.1555V39.6Z" fill="white"/>
            <path d="M96 28.5173C96 35.7993 90.8734 41 83.5 41C76.1266 41 71 35.7993 71 28.5173C71 21.2353 76.1266 16 83.5 16C90.8734 16 96 21.2369 96 28.5173ZM91.2938 28.5173C91.2938 23.6752 88.0599 20.2333 83.5 20.2333C78.9401 20.2333 75.7046 23.6752 75.7046 28.5173C75.7046 33.3594 78.9386 36.7667 83.5 36.7667C88.0614 36.7667 91.2938 33.3594 91.2938 28.5173Z" fill="white"/>
            <path d="M103.641 17V36.1377H111V40H99V17H103.641Z" fill="white"/>
            <path d="M117 17V40H112V17H117Z" fill="white"/>
            <path d="M126.6 36.1377H137.437V40H119L130.4 20.8638H120.795V17H138L126.6 36.1377Z" fill="white"/>
            <path d="M153 20.8631H144.568V26.0037H152.649V29.8653H144.568V36.1384H153V40H140V17H153V20.8631Z" fill="white"/>
            <path d="M160.609 17V31.747C160.609 33.5424 161.21 34.6338 161.742 35.2312C162.415 36.0416 163.656 36.8505 165.5 36.8505C167.344 36.8505 168.583 36.0416 169.258 35.2312C169.79 34.6338 170.391 33.5424 170.391 31.747V17H175V31.3565C175 33.5732 174.645 35.8332 173.227 37.6208C171.527 39.8376 168.549 41 165.5 41C162.451 41 159.473 39.8376 157.773 37.6208C156.355 35.8255 156 33.5732 156 31.3565V17H160.609Z" fill="white"/>
            <path d="M181.414 17H184.764L191.07 31.62L197.661 17H201.009L205 40H200.297L198.159 25.8277L191.782 40H190.219L184.125 25.8277L181.703 40H177L181.414 17Z" fill="white"/>
            <defs>
              <linearGradient id="dark_paint0_linear" x1="27.6655" y1="13.3308" x2="27.6655" y2="45.552" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#949494"/>
              </linearGradient>
              <linearGradient id="dark_paint1_linear" x1="22" y1="0" x2="22" y2="56.1405" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#949494"/>
              </linearGradient>
            </defs>
            </svg>
          : <svg xmlns="http://www.w3.org/2000/svg" width="205" height="57" viewBox="0 0 205 57" fill="none">
            <path d="M37.388 13.3309L33.5572 19.7743H21.7754L17.9446 13.3386L13.5684 20.7549L27.6655 45.5521L41.7627 20.7534L37.388 13.3309ZM26.714 36.0856L20.9219 22.7883L26.714 28.5543V36.0856ZM28.6554 36.0856V28.5513L34.4475 22.7852L28.6554 36.0856Z" fill="#898989"/>
            <path d="M37.388 13.3309L33.5572 19.7743H21.7754L17.9446 13.3386L13.5684 20.7549L27.6655 45.5521L41.7627 20.7534L37.388 13.3309ZM26.714 36.0856L20.9219 22.7883L26.714 28.5543V36.0856ZM28.6554 36.0856V28.5513L34.4475 22.7852L28.6554 36.0856Z" fill="url(#paint0_linear)"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="#898989"/>
            <path d="M28.1943 50.3729C24.7012 50.3921 21.2523 49.5907 18.1256 48.033C14.9989 46.4753 12.2818 44.2051 10.1932 41.4051C8.10453 38.6051 6.70276 35.3536 6.10078 31.9127C5.49881 28.4717 5.71346 24.9375 6.72746 21.5947C7.74145 18.2519 9.52643 15.194 11.9386 12.6673C14.3508 10.1407 17.3227 8.21594 20.6149 7.04815C23.9071 5.88035 27.4276 5.50217 30.8928 5.94406C34.3579 6.38595 37.6708 7.63556 40.5646 9.59223L44 4.95702C39.3187 1.72113 33.761 -0.00830368 28.0702 2.9977e-05C12.5649 2.9977e-05 0 12.5649 0 28.0702C0 43.5756 12.5649 56.1404 28.0702 56.1404C33.4501 56.1481 38.7178 54.6019 43.24 51.6876L40.1279 46.8332C36.569 49.1271 32.4283 50.3552 28.1943 50.3729Z" fill="url(#paint1_linear)"/>
            <path d="M69 39.6C67.6047 40.3535 65.7565 41 63.2443 41C59.1639 41 56.6869 39.5653 54.7331 37.6635C52.0465 35.0097 51 32.0334 51 28.5176C51 24.179 52.7442 21.058 54.7331 19.1577C57.0709 16.8972 59.9656 16.0006 63.2795 16.0006C65.2772 15.9806 67.2466 16.487 69 17.4714V22.9237C68.305 22.089 67.4441 21.417 66.4753 20.953C65.5065 20.489 64.4522 20.2437 63.3835 20.2337C58.7095 20.2337 55.6756 24.179 55.6756 28.5899C55.6756 33.8975 59.6535 36.77 63.5579 36.77C64.5965 36.774 65.623 36.5417 66.5639 36.0897C67.5048 35.6377 68.3367 34.9772 69 34.1555V39.6Z" fill="#898989"/>
            <path d="M69 39.6C67.6047 40.3535 65.7565 41 63.2443 41C59.1639 41 56.6869 39.5653 54.7331 37.6635C52.0465 35.0097 51 32.0334 51 28.5176C51 24.179 52.7442 21.058 54.7331 19.1577C57.0709 16.8972 59.9656 16.0006 63.2795 16.0006C65.2772 15.9806 67.2466 16.487 69 17.4714V22.9237C68.305 22.089 67.4441 21.417 66.4753 20.953C65.5065 20.489 64.4522 20.2437 63.3835 20.2337C58.7095 20.2337 55.6756 24.179 55.6756 28.5899C55.6756 33.8975 59.6535 36.77 63.5579 36.77C64.5965 36.774 65.623 36.5417 66.5639 36.0897C67.5048 35.6377 68.3367 34.9772 69 34.1555V39.6Z" fill="url(#paint2_linear)"/>
            <path d="M96 28.5173C96 35.7993 90.8734 41 83.5 41C76.1266 41 71 35.7993 71 28.5173C71 21.2353 76.1266 16 83.5 16C90.8734 16 96 21.2369 96 28.5173ZM91.2938 28.5173C91.2938 23.6752 88.0599 20.2333 83.5 20.2333C78.9401 20.2333 75.7046 23.6752 75.7046 28.5173C75.7046 33.3594 78.9386 36.7667 83.5 36.7667C88.0614 36.7667 91.2938 33.3594 91.2938 28.5173Z" fill="#898989"/>
            <path d="M96 28.5173C96 35.7993 90.8734 41 83.5 41C76.1266 41 71 35.7993 71 28.5173C71 21.2353 76.1266 16 83.5 16C90.8734 16 96 21.2369 96 28.5173ZM91.2938 28.5173C91.2938 23.6752 88.0599 20.2333 83.5 20.2333C78.9401 20.2333 75.7046 23.6752 75.7046 28.5173C75.7046 33.3594 78.9386 36.7667 83.5 36.7667C88.0614 36.7667 91.2938 33.3594 91.2938 28.5173Z" fill="url(#paint3_linear)"/>
            <path d="M103.641 17V36.1377H111V40H99V17H103.641Z" fill="#898989"/>
            <path d="M103.641 17V36.1377H111V40H99V17H103.641Z" fill="url(#paint4_linear)"/>
            <path d="M117 17V40H112V17H117Z" fill="#898989"/>
            <path d="M117 17V40H112V17H117Z" fill="url(#paint5_linear)"/>
            <path d="M126.6 36.1377H137.437V40H119L130.4 20.8638H120.795V17H138L126.6 36.1377Z" fill="#898989"/>
            <path d="M126.6 36.1377H137.437V40H119L130.4 20.8638H120.795V17H138L126.6 36.1377Z" fill="url(#paint6_linear)"/>
            <path d="M153 20.8631H144.568V26.0037H152.649V29.8653H144.568V36.1384H153V40H140V17H153V20.8631Z" fill="#898989"/>
            <path d="M153 20.8631H144.568V26.0037H152.649V29.8653H144.568V36.1384H153V40H140V17H153V20.8631Z" fill="url(#paint7_linear)"/>
            <path d="M160.609 17V31.747C160.609 33.5424 161.21 34.6338 161.742 35.2312C162.415 36.0416 163.656 36.8505 165.5 36.8505C167.344 36.8505 168.583 36.0416 169.258 35.2312C169.79 34.6338 170.391 33.5424 170.391 31.747V17H175V31.3565C175 33.5732 174.645 35.8332 173.227 37.6208C171.527 39.8376 168.549 41 165.5 41C162.451 41 159.473 39.8376 157.773 37.6208C156.355 35.8255 156 33.5732 156 31.3565V17H160.609Z" fill="#898989"/>
            <path d="M160.609 17V31.747C160.609 33.5424 161.21 34.6338 161.742 35.2312C162.415 36.0416 163.656 36.8505 165.5 36.8505C167.344 36.8505 168.583 36.0416 169.258 35.2312C169.79 34.6338 170.391 33.5424 170.391 31.747V17H175V31.3565C175 33.5732 174.645 35.8332 173.227 37.6208C171.527 39.8376 168.549 41 165.5 41C162.451 41 159.473 39.8376 157.773 37.6208C156.355 35.8255 156 33.5732 156 31.3565V17H160.609Z" fill="url(#paint8_linear)"/>
            <path d="M181.414 17H184.764L191.07 31.62L197.661 17H201.009L205 40H200.297L198.159 25.8277L191.782 40H190.219L184.125 25.8277L181.703 40H177L181.414 17Z" fill="#898989"/>
            <path d="M181.414 17H184.764L191.07 31.62L197.661 17H201.009L205 40H200.297L198.159 25.8277L191.782 40H190.219L184.125 25.8277L181.703 40H177L181.414 17Z" fill="url(#paint9_linear)"/>
            <defs>
              <linearGradient id="paint0_linear" x1="27.6655" y1="13.3309" x2="27.6655" y2="45.5521" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="22" y1="0" x2="22" y2="56.1405" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint2_linear" x1="60" y1="16" x2="60" y2="41" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint3_linear" x1="83.5" y1="16" x2="83.5" y2="41" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint4_linear" x1="105" y1="17" x2="105" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint5_linear" x1="114.5" y1="17" x2="114.5" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint6_linear" x1="128.5" y1="17" x2="128.5" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint7_linear" x1="146.5" y1="17" x2="146.5" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint8_linear" x1="165.5" y1="17" x2="165.5" y2="41" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
              <linearGradient id="paint9_linear" x1="191" y1="17" x2="191" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1C1F21" stopOpacity="0.13"/>
                <stop offset="1" stopColor="#202324"/>
              </linearGradient>
            </defs>
          </svg>
      }
    </>
  )

export default Logo

