import React,{ useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { noop } from 'lodash'
import triangle from 'img/modals/triangle.svg'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'

export interface ModalProps {
  active?: boolean
  onClose?(): void
  children?: React.ReactNode
}

const portal = document.getElementById('root')

function ModalTemplate(props: ModalProps) {
  const {
    active=false,
    onClose=noop,
    children=null,
  } = props

  const modalWindow = useRef(null)

  useOnClickOutside({
    ref: modalWindow,
    handler: () => onClose()
  });

  if (!portal || !active) {
    return null
  }

  return createPortal(
    <ModalTemplateRoot>
      <Modal ref={modalWindow}>
        <Close
          type="button"
          onClick={onClose}
        />
        <Content>
          {children && children}
        </Content>
        <ModalFigure />
      </Modal>
    </ModalTemplateRoot>,
    portal
  )
}

export default ModalTemplate

const ModalTemplateRoot = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const Modal = styled.div`
  position: relative;
  width: 1599px;
  height: 750px;
  ${props => props.theme.adaptive.modals.xl} {
    width: 1399px;
    height: 656px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    width: 1199px;
    height: 563px;
  }
  ${props => props.theme.adaptive.modals.md} {
    width: 1033px;
    height: 484px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 700px;
    height: 505px;
  }
`

const Content = styled.div`
  position: relative;
  padding: 0 59px 0 45px;
  z-index: 2;
  height: 100%;
  ${props => props.theme.adaptive.modals.xl} {
    padding: 0 52px 0 40px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    padding: 0 56px 0 34px;
  }
  ${props => props.theme.adaptive.modals.md} {
    padding: 0 47px 0 30px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    padding: 0 35px 0 31px;
  }
`

const ModalFigure = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(22, 23, 23, .95);
  width: 1453px;
  height: 100%;
  border-radius: 6px 0 0 6px;
  ${props => props.theme.adaptive.modals.xl} {
    width: 1257px;
    border-radius: 5px 0 0 5px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    width: 1078px;
  }
  ${props => props.theme.adaptive.modals.md} {
    width: 928px;
    border-radius: 4px 0 0 4px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 635px;
    border-radius: 3px 0 0 3px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 146px;
    height: calc(100% - 150px);
    left: 100%;
    bottom: 0;
    background: rgba(22, 23, 23, .95);
    border-radius: 0 0 6px 0;
    ${props => props.theme.adaptive.modals.xl} {
      width: 142px;
      height: calc(100% - 133px);
      border-radius: 0 0 5px 0;
    }
    ${props => props.theme.adaptive.modals.lg} {
      width: 121px;
      height: calc(100% - 114px);
    }
    ${props => props.theme.adaptive.modals.md} {
      width: 105px;
      height: calc(100% - 98px);
      border-radius: 0 0 4px 0;
    }
    ${props => props.theme.adaptive.modals.sm} {
      width: 65px;
      height: calc(100% - 68px);
      border-radius: 0 0 3px 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 146px;
    height: 150px;
    left: 100%;
    top: 0;
    background: url(${triangle}) no-repeat center;
    ${props => props.theme.adaptive.modals.xl} {
      width: 142px;
      height: 133px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      width: 121px;
      height: 114px;
    }
    ${props => props.theme.adaptive.modals.md} {
      width: 105px;
      height: 98px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      width: 65px;
      height: 68px;
    }
  }
`

const Close = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid #FCFCFC;
  cursor: pointer;
  z-index: 11;
  ${props => props.theme.adaptive.modals.xl} {
    width: 57px;
    height: 57px;
  }
  ${props => props.theme.adaptive.modals.lg} {
    width: 49px;
    height: 49px;
  }
  ${props => props.theme.adaptive.modals.md} {
    width: 42px;
    height: 42px;
  }
  ${props => props.theme.adaptive.modals.sm} {
    width: 29px;
    height: 29px;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    left: 15px;
    top: 30px;
    width: 35px;
    height: 1px;
    background: #fff;
    ${props => props.theme.adaptive.modals.xl} {
      left: 12px;
      top: 28px;
      width: 30px;
    }
    ${props => props.theme.adaptive.modals.lg} {
      left: 11px;
      top: 24px;
      width: 26px;
    }
    ${props => props.theme.adaptive.modals.md} {
      left: 9px;
      top: 20px;
      width: 22px;
    }
    ${props => props.theme.adaptive.modals.sm} {
      left: 6px;
      top: 13px;
      width: 14px;
    }
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
