import HitBTC from "../components/Icons/partners/HitBTC";
import Cexio from "../components/Icons/partners/Cexio";
import Ihodl from "../components/Icons/partners/Ihodl";
import Gnosis from "../components/Icons/partners/Gnosis";
import Exante from "../components/Icons/partners/Exante";
import Exantech from "../components/Icons/partners/Exantech";
import Amoveo from "../components/Icons/partners/Amoveo";
import Cointelegraph from "../components/Icons/partners/Cointelegraph";
import Ledger from "../components/Icons/partners/Ledger";
import Stasis from "../components/Icons/partners/Stasis";
import Comino from "../components/Icons/partners/Comino";
import Beetroot from "../components/Icons/partners/Beetroot";
import Source from "../components/Icons/partners/Source";
import Occam from "../components/Icons/partners/Occam";


export default [
  {
    getIcon: () => <Cexio />,
    link: 'https://cex.io/',
  },
  {
    getIcon: () => <Ihodl />,
    link: 'https://ihodl.com/',
  },
  {
    getIcon: () => <Exante />,
    link: 'https://exante.eu/',
  },
  {
    getIcon: () => <Exantech />,
    link: 'https://exan.tech/',
  },
  {
    getIcon: () => <Amoveo />,
    link: 'https://amoveo.io/',
  },
  {
    getIcon: () => <Cointelegraph />,
    link: 'https://cointelegraph.com/',
  },
  {
    getIcon: () => <Stasis />,
    link: 'https://stasis.net/',
  },
  {
    getIcon: () => <Comino />,
    link: 'https://comino.com/en/',
  },
  {
    getIcon: () => <Beetroot />,
    link: 'https://www.beetrootlab.com/',
  },
  {
    getIcon: () => <Source />,
    link: 'https://www.profluentgroup.pr',
  },
  {
    getIcon: () => <Occam />,
    link: 'https://occam.fi',
  },
]
