import Apple from "../components/Icons/index/Apple";
import Google from "../components/Icons/index/Google";
import Gallery from "../components/Icons/index/Gallery";
import Galaxy from "../components/Icons/index/Galaxy";

export default [
  {
    getIcon: () => <Apple />,
    height: '32',
    padding: 7,
    link: 'https://apps.apple.com/lv/app/dystopia-rebel-empires/id1501664800',
  },
  {
    getIcon: () => <Google />,
    height: '29',
    padding: 7,
    link: 'https://play.google.com/store/apps/details?id=com.beetrootlab.dystopia',
  },
  {
    getIcon: () => <Gallery />,
    height: '31',
    padding: 7,
    link: 'https://appgallery.huawei.com/#/app/C102948387',
  },
  {
    getIcon: () => <Galaxy />,
    height: '32px',
    padding: 6,
    link: 'https://galaxystore.samsung.com/detail/com.beetrootlab.dystopia.gs',
  },
]
