import React from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import linkedInIcon from '../../img/team/linkedin-icon.svg'
import teamBg from '../../img/team/bg.png'
import personsList from "../../store/persons";

export default function Team() {
  return (
    <Root>
      <PageWrapper>
        <Container>
          <Title>
            Team
          </Title>
          <List>
            {personsList.map((person, idx) => {
              return (
                <Person>
                  <Photo src={person.photo} />
                  <InfoBlock>
                    <TextBlock>
                      <Name>
                        {person.name}
                      </Name>
                      <Position>
                        {person.position}
                      </Position>
                      <Email href={`mailto:${person.email}`} target={'_blank'}>
                        {person.email}
                      </Email>
                    </TextBlock>
                    {person.linkedIn &&
                      <LinkedIn href={person.linkedIn} target={'_blank '} />
                    }
                  </InfoBlock>
                </Person>
              )
            })}
          </List>
        </Container>
      </PageWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1920px;
    height: 321px;
    background: url("${teamBg}") center no-repeat;
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
`

const Container = styled.div`
  width: 1636px;
  margin: auto;
  ${props => props.theme.adaptive.index.xl} {
    width: 1390px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1199px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 961px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1031px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 700px;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
    margin-bottom: 8px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 14px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 38px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 12px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 13px;
  }
`

const List = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const Person = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 361px;
  height: 110px;
  margin-right: 48px;
  margin-top: 58px;
  ${props => props.theme.adaptive.index.xl} {
    width: 316px;
    height: 96px;
    margin-right: 42px;
    margin-top: 51px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 289px;
    height: 88px;
    margin-right: 14px;
    margin-top: 31px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-right: 47px;
    margin-top: 19px;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 248px;
    height: 76px;
    margin-right: 13px;
    margin-top: 26px;
    &:nth-child(3n+3) {
      margin-right: 13px;
    }
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 226px;
    height: 69px;
    margin-right: 11px;
    margin-top: 18px;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
  &:nth-child(4n+4) {
    margin-right: 0;
    ${props => props.theme.adaptive.index.md} {
      margin-right: 47px;
    }
    ${props => props.theme.adaptive.index.smd} {
      margin-right: 0;
    }
    ${props => props.theme.adaptive.index.sm} {
      margin-right: 11px;
    }
  }
`

const Photo = styled.img`
  display: block;
  width: 110px;
  height: 110px;
  border-radius: 8px;
  margin-right: 21px;
  ${props => props.theme.adaptive.index.xl} {
    width: 96px;
    height: 96px;
    border-radius: 7px;
    margin-right: 19px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 88px;
    height: 88px;
    border-radius: 6px;
    margin-right: 17px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 76px;
    height: 76px;
    margin-right: 14px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 69px;
    height: 69px;
    margin-right: 13px;
  }
`

const InfoBlock = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.theme.adaptive.index.xl} {
    width: 201px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 184px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 158px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 144px;
  }
`

const TextBlock = styled.div`
  width: 100%;
`

const Name = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 28px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 19px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 16px;
    line-height: 21px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 15px;
    line-height: 20px;
  }
`

const Position = styled.div`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #353636;
  opacity: 0.4;
  margin-bottom: 1px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 11px;
    line-height: 15px;
  }
`

const Email = styled.a`
  display: block;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #353636;
  opacity: 0.4;
  transition: 0.4s;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 11px;
    line-height: 15px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 11px;
    line-height: 15px;
  }
  &:hover {
    opacity: 0.8;
  }
`

const LinkedIn = styled.a`
  width: 82px;
  height: 21px;
  background: url("${linkedInIcon}") center no-repeat;
  background-size: cover;
  ${props => props.theme.adaptive.index.xl} {
    width: 72px;
    height: 17px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 66px;
    height: 16px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 58px;
    height: 14px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 52px;
    height: 13px;
  }
`
