import React, { useState } from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import Arrow from "../Icons/product/Arrow";
import productsBg from "../../img/product/bg2.png";
import conorImage from "../../img/product/conor-image.png";
import productsList from "../../store/products";
import ProductTournamentModal from 'components/Modals/ProductTournament'
import ProductPrediction from 'components/Modals/ProductPrediction'
import ProductAttention from 'components/Modals/ProductAttention'
import ProductMLM from 'components/Modals/ProductMLM'

interface Color {
  color?: string
}

export default function Products() {
  const [tournamentModalOpen, setTournamentModalOpen] = useState(false)
  const [predictionModalOpen, setPredictionModalOpen] = useState(false)
  const [attentionModalOpen, setAttentionModalOpen] = useState(false)
  const [mlmModalOpen, setMlmModalOpen] = useState(false)
  const openProductModal = (product: {slug: string}): void => {
    switch (product.slug) {
      case 'tournaments':
        setTournamentModalOpen(true)
        break;
      case 'prediction-markets':
        setPredictionModalOpen(true)
        break;
      case 'attention-marketplace':
        setAttentionModalOpen(true)
        break;
      case 'mlm':
        setMlmModalOpen(true)
        break;
    }
  }
  return (
    <>
      <ProductTournamentModal
        active={tournamentModalOpen}
        onClose={() => setTournamentModalOpen(false)}
        onNext={() => setPredictionModalOpen(true)}
      />
      <ProductPrediction
        active={predictionModalOpen}
        onClose={() => setPredictionModalOpen(false)}
        onNext={() => setAttentionModalOpen(true)}
      />
      <ProductAttention
        active={attentionModalOpen}
        onClose={() => setAttentionModalOpen(false)}
        onNext={() => setMlmModalOpen(true)}
      />
      <ProductMLM
        active={mlmModalOpen}
        onClose={() => setMlmModalOpen(false)}
        onNext={() => setTournamentModalOpen(true)}
      />
      <Root>
        <PageWrapper>
          <ConorImage />
          <Container>
            <Title>
              Colizeum Products
            </Title>
            <List>
              {productsList.map((product, idx) => {
                return (
                  <Product>
                    <Image src={product.image} />
                    <TextBlock>
                      <Name>
                        {product.name}
                      </Name>
                      <Description>
                        {product.description}
                      </Description>
                      <LinkWrap onClick={() => openProductModal(product)}>
                        <LearnMore color={product.color}>
                          Learn more
                        </LearnMore>
                        <ArrowWrap>
                          <Arrow fill={product.color} />
                        </ArrowWrap>
                      </LinkWrap>
                    </TextBlock>
                  </Product>
                )
              })}
            </List>
          </Container>
        </PageWrapper>
      </Root>
    </>
  )
}

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: url("${productsBg}") center no-repeat;
    background-size: cover;
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
`

const ConorImage = styled.div`
  position: absolute;
  width: 786px;
  height: 792px;
  top: 220px;
  right: -34px;
  background: url("${conorImage}") center no-repeat;
  ${props => props.theme.adaptive.index.xl} {
    background-size: cover;
    width: 658px;
    height: 682px;
    top: 193px;
    right: -20px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 564px;
    height: 585px;
    top: 165px;
    right: 0;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 530px;
    height: 550px;
    top: 163px;
    right: -25px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 486px;
    height: 504px;
    top: 142px;
    right: 0;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const Container = styled.div`
  position: relative;
  width: 1600px;
  margin: auto;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 700px;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 11px;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
    margin-bottom: 9px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 7px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 0;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 4px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 6px;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1207px;
  ${props => props.theme.adaptive.index.xl} {
    width: 1070px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 918px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 798px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 820px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 100%;
  }
`

const Product = styled.div`
  position: relative;
  display: flex;
  width: 585px;
  height: auto;
  padding: 29px 29px 29px 0;
  margin-top: 34px;
  background: linear-gradient(93deg, #FFFFFF 5%, #FBFCFE 109%);
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  cursor: pointer;
  transition: 0.4s;
  ${props => props.theme.adaptive.index.xl} {
    width: 520px;
    padding: 25px 28px 25px 0;
    margin-top: 30px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 446px;
    padding: 21px 42px 21px 0;
    margin-top: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 384px;
    padding: 18px 36px 20px 0;
    margin-top: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 400px;
    padding: 18px 21px 20px 0;
    margin-top: 20px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 342px;
    padding: 17px 17px 19px 0;
    margin-top: 17px;
  }
  &:nth-child(2n+2) {
    margin-right: 0;
  }
  &:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  }
`

const Image = styled.img`
  display: block;
  width: 131px;
  height: auto;
  ${props => props.theme.adaptive.index.xl} {
    width: 115px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 99px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 85px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 85px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 83px;
  }
`

const TextBlock = styled.div`
  width: 425px;
  ${props => props.theme.adaptive.index.xl} {
    width: 377px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 305px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 263px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 294px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 246px;
  }
`

const Name = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #353636;
  margin-bottom: 1px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 23px;
    line-height: 31px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 20px;
    line-height: 26px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 17px;
    line-height: 23px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 17px;
    line-height: 23px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 16px;
    line-height: 21px;
  }
`

const Description = styled.div`
  width: 100%;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.05em;
  color: #353636;
  margin-bottom: 14px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 11px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 9px;
  }
`

const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const LearnMore = styled.div<Color>`
  position: relative;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: -0.05em;
  margin-right: 11px;
  color: ${({ color }) => color ? color : '#FFCA6A'};
  ${props => props.theme.adaptive.index.xl} {
    font-size: 18px;
    line-height: 24px;
    margin-right: 10px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 15px;
    line-height: 21px;
    margin-right: 9px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 14px;
    line-height: 19px;
    margin-right: 6px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 13px;
    line-height: 18px;
    margin-right: 7px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
    margin-right: 5px;
  }
`

const ArrowWrap = styled.div<Color>`
  position: relative;
  top: 3px;
  height: 9px;
  ${props => props.theme.adaptive.index.xl} {
    height: 8px;
  }
  ${props => props.theme.adaptive.index.lg} {
    top: 2px;
    height: 7px;
  }
  ${props => props.theme.adaptive.index.md} {
    height: 6px;
  }
  ${props => props.theme.adaptive.index.smd} {
    height: 6px;
  }
  ${props => props.theme.adaptive.index.sm} {
    height: 6px;
  }
  svg {
    display: block;
    width: auto;
    height: 100%;
  }
`
