import React from 'react'
import { Props } from './Product'
import Product from './Product'
import attentionIcon from 'img/modals/products/attention-icon.png'
import attentionOne from 'img/modals/products/attention_1.svg'
import attentionTwo from 'img/modals/products/attention_2.svg'

function ProductAttention(props: Props) {
  const {
    active,
    onClose,
    onNext,
  } = props

  const modalData = {
    title: 'Attention marketplace',
    iconPath: attentionIcon,
    image: [attentionOne, attentionTwo],
    steps: [
      'Game Devs need to register at Colizeum Game Marketplace. Doing so is free by default, however, priority of games being displayed depends on the amount of ZEUM a gamedev has staked. Thus, the stake of ZEUM facilitates capturing gamers’ attention.',
      'The more prominent the place where the game is being advertised, the higher the required ZEUM stake.',
      'Access of Game Devs to Coliseum game marketplace is vetted by the Colizeum DAO. Other than that, it’s permissionless.',
      `Colizeum provides Attention Widgets. These widgets facilitate in-game monetization of gamers’ attention.`,
      `Colizeum Game Marketplace also offers direct banner placements for advertisers (be it Game Devs, or other parties). The position of any particular banner is a function of ZEUM staked by the advertiser.`,
    ]
  }
  return (
    <Product
      type="attention"
      active={active}
      content={modalData}
      onClose={onClose}
      onNext={onNext}
    />
  )
}

export default ProductAttention
