import antonPhoto from '../img/team/persons/anton.jpg'
import bertPhoto from '../img/team/persons/bert.jpg'
import davisPhoto from '../img/team/persons/davis.jpg'
import denisPhoto from '../img/team/persons/denis.jpg'
import edgarsPhoto from '../img/team/persons/edgars.jpg'
import gatisPhoto from '../img/team/persons/gatis.jpg'
import girtsPhoto from '../img/team/persons/girts.jpg'
import josePhoto from '../img/team/persons/jose.jpg'
import karlisPhoto from '../img/team/persons/karlis.jpg'
import tarasPhoto from '../img/team/persons/taras.jpg'
import tomsPhoto from '../img/team/persons/toms.jpg'

export default [
  {
    name: 'Davis Ziedins',
    position: 'General Manager',
    email: 'davis@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/davisziedins/',
    photo: davisPhoto,
  },
  {
    name: 'Edgars Vecozolins',
    position: 'CTO',
    email: 'edgars@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/edgars-vecozoli%C5%86%C5%A1-9',
    photo: edgarsPhoto,
  },
  {
    name: 'Gatis Eglitis',
    position: 'Product and Finance Advisor',
    email: 'gatis@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/gatis-eglitis-a324734/',
    photo: gatisPhoto,
  },
  {
    name: 'Bert Mouler',
    position: 'Token Economics Engineer',
    email: 'bert@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/bertmouler/',
    photo: bertPhoto,
  },
  {
    name: 'José Rodríguez',
    position: 'Head of PR',
    email: 'jose@colizeum.io',
    photo: josePhoto,
  },
  {
    name: 'Denis Voskvitsov ',
    position: 'Crypto Product Advisor',
    email: 'denis@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/dvoskvitsov/',
    photo: denisPhoto,
  },
  {
    name: 'Toms Zvirgzdins',
    position: 'Head of Communication',
    email: 'toms@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/toms-zvirgzdins/',
    photo: tomsPhoto,
  },
  {
    name: 'Karlis Liepins',
    position: 'DeFi Manager / Market Maker',
    email: 'karlis@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/karlliepins/',
    photo: karlisPhoto,
  },
  {
    name: 'Anton Agofonov',
    position: 'Communication Advisor',
    email: 'anton@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/aagafonov/',
    photo: antonPhoto,
  },
  {
    name: 'Taras Pasechnik',
    position: 'Dev. Project Manager',
    email: 'taras@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/taras-pasechnik-53001732/',
    photo: tarasPhoto,
  },
  {
    name: 'Girts Vikelis',
    position: 'Head of Operations',
    email: 'girts@colizeum.io',
    linkedIn: 'https://www.linkedin.com/in/girts-vikelis-18bb3b68/',
    photo: girtsPhoto,
  },
]
