import React from "react";
import styled,{ css } from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import bg from '../../img/reasons/bg.png'
import Icon from "../Icons/reasons/Icon";
import cardanoImage from '../../img/reasons/cardano.svg'
import middleIcon from '../../img/reasons/middle-icon.svg'
import lightBg from '../../img/reasons/light.svg'
import connectionsBg from '../../img/reasons/connections.png'

interface IconProps {
  color?: string
  right?: boolean
}

const Reasons: React.FunctionComponent = () => (
  <Root>
    <PageWrapper dark={true}>
      <Container>
        <TitleWrap>
          <TitleLow>
            Initial audience spans accross <More>{'>'}</More><Count>1 000 000 users</Count>
          </TitleLow>
          <Title>
            7 reasons
          </Title>
        </TitleWrap>
        <Row>
          <FirstReason>
            <ReasonIcon color={'#DF6DEF'}>
              <ReasonNumber color={'#DF6DEF'}>
                1
              </ReasonNumber>
              <Icon />
            </ReasonIcon>
            <FirstHighText>
              Monetization and user acquisition are <span>dominated by middlemen</span>.
            </FirstHighText>
            <FirstLowText>
              Ad Exchanges charge exuberant fees which are ultimately passed on to and paid by the consumers.
            </FirstLowText>
          </FirstReason>
          <SecondReason>
            <ReasonRow>
              <ReasonIcon color={'#FFCA6A'}>
                <ReasonNumber color={'#FFCA6A'}>
                  2
                </ReasonNumber>
                <Icon />
              </ReasonIcon>
              <SecondLowText>
                The mobile gaming market is becoming more competitive every year making game developers look for alternative ways to monetize and acquire new users.
              </SecondLowText>
            </ReasonRow>
            <SecondHighText>
              Mobile Cash based games have been growing rapidly with <span>80% YoY growth since 2020</span>.
            </SecondHighText>
          </SecondReason>
        </Row>
        <Row>
          <ThirdReason>
            <ReasonIcon color={'#959595'}>
              <ReasonNumber>
                3
              </ReasonNumber>
              <Icon />
            </ReasonIcon>
            <ThirdLowText>
              While delivering the majority of value, <span>game developers are deprived from any share of massive derivative markets (such as betting)</span> that flourish around gaming.
            </ThirdLowText>
          </ThirdReason>
          <Middle>
            <Light />
            <Connections />
          </Middle>
          <FourthReason>
            <ReasonIcon color={'#959595'}>
              <ReasonNumber>
                4
              </ReasonNumber>
              <Icon />
            </ReasonIcon>
            <FourthLowText>
              <span>Building infrastructure for otherwise standardized services</span>, such as selling of banner space and creating referal schemes (a.k.a. MLM) <span>is expensive and distracts from core business of building good games</span>.
            </FourthLowText>
          </FourthReason>
        </Row>
        <Row>
          <FifthReason>
            <ReasonRow>
              <FifthHighText>
                COLIZEUM is a host of blockchain based services.
              </FifthHighText>
              <ReasonIcon right={true}>
                <ReasonNumber>
                  5
                </ReasonNumber>
                <Icon />
              </ReasonIcon>
            </ReasonRow>
            <FifthLowText>
              <span>that solve all of the above</span> while creating an entirely new dimension of cooperative gaming.
            </FifthLowText>
          </FifthReason>
          <SixthReason>
            <ReasonRow>
              <ReasonIcon color={'#64E8FA'}>
                <ReasonNumber color={'#64E8FA'}>
                  6
                </ReasonNumber>
                <Icon />
              </ReasonIcon>
              <SixthHighText>
                <More color={'#64E8FA'}>{'>'}</More><span>10 years</span> of blockchain and gaming expirience and <More color={'#64E8FA'}>{'>'}</More><span>$1b+ in exits value</span>.
              </SixthHighText>
            </ReasonRow>
            <SixthLowText>
              COLIZEUM is built by the industry leading team of experts.
            </SixthLowText>
          </SixthReason>
          <SeventhReason>
            <ReasonRow>
              <ReasonIcon>
                <ReasonNumber>
                  7
                </ReasonNumber>
                <Icon />
              </ReasonIcon>
              <SeventhHighText>
                <span>Colizeum is being built</span> on Cardano to capture the technological excelence embedded in this platform.
              </SeventhHighText>
            </ReasonRow>
            <SeventhLowText>
              and involve (and enrich) it’s massive and loyal community tapping <span>directly into the $50b+ pools of on-chain liqudiity</span>.
            </SeventhLowText>
          </SeventhReason>
        </Row>
      </Container>
    </PageWrapper>
  </Root>
)

export default Reasons

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #151515;
  &:before {
    content: '';
    position: absolute;
    width: 1920px;
    height: 1078px;
    top: 0;
    left: 0;
    mix-blend-mode: soft-light;
    opacity: 0.15;
    background: url("${bg}");
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
  @media screen and (max-width: 800px) {
    background: transparent;
  }
`

const Container = styled.div`
  position: relative;
  width: 1600px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 700px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  ${props => props.theme.adaptive.index.xl} {
    margin-bottom: 21px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-bottom: 18px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-bottom: 8px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-bottom: 3px;
  }
`

const TitleLow = styled.div`
  min-width: 619px;
  font-weight: 100;
  font-size: 29px;
  line-height: 39px;
  letter-spacing: -0.045em;
  color: rgba(255,255,255,0.5);
  margin-right: 34px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 26px;
    line-height: 35px;
    min-width: 562px;
    margin-right: 9px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 21px;
    line-height: 28px;
    min-width: 439px;
    margin-right: 43px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 18px;
    line-height: 24px;
    min-width: 378px;
    margin-right: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 19px;
    line-height: 25px;
    min-width: 400px;
    margin-right: 15px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 18px;
    line-height: 24px;
    min-width: 378px;
    margin-right: 21px;
  }
`

const More = styled.span<IconProps>`
  color: ${({ color }) => color ? color : '#DF6DEF'};
`

const Count = styled.span`
  color: #FFFFFF;
`

const Title = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #FFFFFF;
  text-transform: uppercase;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 48px;
    line-height: 60px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
  }
`

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 39px;
  ${props => props.theme.adaptive.index.xl} {
    margin-bottom: 35px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-bottom: 28px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-bottom: 24px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-bottom: 26px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-bottom: 0;
    flex-wrap: wrap;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const ReasonCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255,255,255,0.12);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 23px 25px 24px 27px;
  overflow: hidden;
  ${props => props.theme.adaptive.index.xl} {
    padding: 20px 26px 22px 23px;
  }
  ${props => props.theme.adaptive.index.lg} {
    padding: 17px 22px 19px 20px;
  }
  ${props => props.theme.adaptive.index.md} {
    padding: 15px 24px 16px 17px;
  }
  ${props => props.theme.adaptive.index.smd} {
    padding: 15px 17px 18px 18px;
  }
  ${props => props.theme.adaptive.index.sm} {
    padding: 12px 15px 14px 17px;
    margin-bottom: 12px;
  }
`

const LowText = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(255,255,255,0.8);
  ${props => props.theme.adaptive.index.xl} {
    font-size: 16px;
    line-height: 22px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 14px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 13px;
    line-height: 18px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 13px;
    line-height: 18px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 17px;
    order: 2;
  }
  span {
    color: #FFFFFF;
    font-weight: bold;
  }
`

const HighText = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.05em;
  color: rgba(255,255,255,0.8);
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 29px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 18px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 16px;
    line-height: 22px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 16px;
    line-height: 22px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 15px;
    line-height: 21px;
    order: 2;
  }
  span {
    color: #FFFFFF;
    font-weight: bold;
  }
`

const ReasonIcon = styled.div<IconProps>`
  position: relative;
  width: 69px;
  height: 89px;
  margin-right: ${({ right }) => right ? '0' : '34px'};
  ${props => props.theme.adaptive.index.xl} {
    background-size: cover;
    width: 61px;
    height: 78px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 52px;
    height: 67px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 35px;
    height: 58px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 47px;
    height: 60px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-right: 20px;
    width: 33px;
    height: 42px;
    order: 1;
  }
  svg {
    width: 100%;
    height: auto;
    fill-opacity: 0.3;
    fill: ${({ color }) => color ? color : '#959595'};
    background-size: cover;
  }
`

const ReasonNumber = styled.div`
  position: absolute;
  top: 12px;
  right: 15px;
  font-weight: 500;
  font-size: 43px;
  line-height: 58px;
  color: ${({ color }) => color ? color : '#959595'};
  ${props => props.theme.adaptive.index.xl} {
    top: 10px;
    right: 13px;
    font-size: 38px;
    line-height: 51px;
  }
  ${props => props.theme.adaptive.index.lg} {
    top: 9px;
    right: 10px;
    font-size: 32px;
    line-height: 44px;
  }
  ${props => props.theme.adaptive.index.md} {
    top: 1px;
    right: 5px;
    font-size: 28px;
    line-height: 38px;
  }
  ${props => props.theme.adaptive.index.smd} {
    top: 8px;
    right: 10px;
    font-size: 29px;
    line-height: 39px;
  }
  ${props => props.theme.adaptive.index.sm} {
    top: 6px;
    right: 6px;
    font-size: 20px;
    line-height: 27px;
  }
`

const FirstReason = styled(ReasonCard)`
  width: 967px;
  height: 137px;
  ${props => props.theme.adaptive.index.xl} {
    width: 846px;
    height: 120px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 725px;
    height: 103px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 623px;
    height: 89px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 653px;
    height: 93px;
  }
  ${props => props.theme.adaptive.index.sm} {
    flex-wrap: wrap;
    width: 353px;
    height: 102px;
  }
`

const FirstHighText = styled.div`
  ${HighText};
  width: 348px;
  margin-right: 42px;
  ${props => props.theme.adaptive.index.xl} {
    width: 304px;
    margin-right: 37px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 261px;
    margin-right: 31px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 231px;
    margin-right: 23px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 235px;
    margin-right: 24px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 242px;
    margin-right: 0;
  }
`

const FirstLowText = styled.div`
  ${LowText};
  width: 405px;
  ${props => props.theme.adaptive.index.xl} {
    width: 365px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 313px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 261px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 289px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 270px;
    margin-left: 53px;
    margin-top: 5px;
  }
`

const SecondReason = styled(ReasonCard)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 592px;
  height: 220px;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 518px;
    height: 192px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 444px;
    height: 164px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 381px;
    height: 141px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 400px;
    height: 148px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 332px;
    height: 134px;
    top: 0;
  }
`

const SecondHighText = styled.div`
  ${HighText};
  width: 504px;
  margin-left: 32px;
  ${props => props.theme.adaptive.index.xl} {
    width: 441px;
    margin-left: 28px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 378px;
    margin-left: 24px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 324px;
    margin-left: 17px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 341px;
    margin-left: 22px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 242px;
    margin-left: 53px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 17px;
  }
`

const SecondLowText = styled.div`
  ${LowText};
  width: 429px;
  ${props => props.theme.adaptive.index.xl} {
    width: 382px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 334px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 297px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 293px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 242px;
  }
`

const ThirdReason = styled(ReasonCard)`
  width: 642px;
  height: 137px;
  ${props => props.theme.adaptive.index.xl} {
    width: 562px;
    height: 119px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 475px;
    height: 102px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 413px;
    height: 88px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 428px;
    height: 92px;
  }
  ${props => props.theme.adaptive.index.sm} {
    position: relative;
    top: -7px;
    align-items: flex-start;
    width: 353px;
    height: 92px;
  }
`

const ThirdLowText = styled.div`
  ${LowText};
  width: 477px;
  ${props => props.theme.adaptive.index.xl} {
    width: 417px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 358px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 307px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 322px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 266px;
  }
`

const FourthReason = styled(ReasonCard)`
  width: 735px;
  height: 137px;
  ${props => props.theme.adaptive.index.xl} {
    width: 643px;
    height: 119px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 551px;
    height: 102px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 473px;
    height: 88px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 496px;
    height: 92px;
  }
  ${props => props.theme.adaptive.index.sm} {
    position: relative;
    top: 32px;
    align-items: flex-start;
    width: 332px;
    height: 106px;
  }
`

const FourthLowText = styled.div`
  ${LowText};
  width: 577px;
  ${props => props.theme.adaptive.index.xl} {
    width: 504px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 432px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 379px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 389px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 242px;
  }
`

const ReasonRow = styled.div`
  display: flex;
`

const FifthReason = styled(ReasonCard)`
  width: 477px;
  height: 211px;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 418px;
    height: 185px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 358px;
    height: 158px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 307px;
    height: 136px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 322px;
    height: 142px;
  }
  ${props => props.theme.adaptive.index.sm} {
    position: relative;
    top: -14px;
    width: 353px;
    height: 137px;
  }

`

const FifthHighText = styled.div`
  ${HighText};
  width: 288px;
  margin-right: 63px;
  ${props => props.theme.adaptive.index.xl} {
    width: 252px;
    margin-right: 56px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 216px;
    margin-right: 48px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 186px;
    margin-right: 41px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 194px;
    margin-right: 43px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 266px;
    margin-right: 0;
  }
`

const FifthLowText = styled.div`
  ${LowText};
  width: 369px;
  ${props => props.theme.adaptive.index.xl} {
    width: 323px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 301px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 263px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 271px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 263px;
    margin-left: 53px;
  }
`

const SixthReason = styled(ReasonCard)`
  width: 450px;
  height: 211px;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 394px;
    height: 185px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 338px;
    height: 158px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 290px;
    height: 136px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 304px;
    height: 142px;
  }
  ${props => props.theme.adaptive.index.sm} {
    position: relative;
    top: 10px;
    width: 332px;
    height: 92px
  }
`

const SixthHighText = styled.div`
  ${HighText};
  width: 275px;
  ${props => props.theme.adaptive.index.xl} {
    width: 241px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 207px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 178px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 186px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 247px;
  }
`

const SixthLowText = styled.div`
  ${LowText};
  width: 397px;
  ${props => props.theme.adaptive.index.xl} {
    width: 348px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 299px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 257px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 269px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 297px;
    margin-top: 5px;
  }
`

const SeventhReason = styled(ReasonCard)`
  position: relative;
  width: 592px;
  height: 211px;
  flex-wrap: wrap;
  ${props => props.theme.adaptive.index.xl} {
    width: 518px;
    height: 185px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 444px;
    height: 158px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 381px;
    height: 136px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 400px;
    height: 142px;
  }
  ${props => props.theme.adaptive.index.sm} {
    position: relative;
    top: -14px;
    width: 100%;
    height: 68px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 27px;
    right: 27px;
    width: 167px;
    height: 156px;
    background: url("${cardanoImage}") center no-repeat;
    opacity: 0.25;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      top: 24px;
      right: 23px;
      width: 146px;
      height: 136px;
    }
    ${props => props.theme.adaptive.index.lg} {
      top: 20px;
      right: 20px;
      width: 125px;
      height: 116px;
    }
    ${props => props.theme.adaptive.index.md} {
      top: 17px;
      right: 17px;
      width: 107px;
      height: 100px;
    }
    ${props => props.theme.adaptive.index.smd} {
      top: 18px;
      right: 18px;
      width: 113px;
      height: 104px;
    }
    ${props => props.theme.adaptive.index.sm} {
      content: unset;
    }
  }
`

const SeventhHighText = styled.div`
  ${HighText};
  width: 408px;
  ${props => props.theme.adaptive.index.xl} {
    width: 357px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 306px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 262px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 276px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 336px;
    margin-right: 18px;
  }
`

const SeventhLowText = styled.div`
  ${LowText};
  width: 525px;
  ${props => props.theme.adaptive.index.xl} {
    width: 459px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 393px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 337px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 354px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 259px;
  }
`

const Middle = styled.div`
  position: relative;
  width: 125px;
  height: 125px;
  background: url("${middleIcon}") center no-repeat;
  ${props => props.theme.adaptive.index.xl} {
    background-size: cover;
    width: 108px;
    height: 108px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 93px;
    height: 93px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 80px;
    height: 80px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 84px;
    height: 84px;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const Connections = styled.div`
  position: absolute;
  width: 1004px;
  height: 216px;
  top: -46px;
  right: -256px;
  background: url("${connectionsBg}") center no-repeat;
  opacity: 0.15;
  ${props => props.theme.adaptive.index.xl} {
    background-size: cover;
    width: 877px;
    height: 188px;
    top: -40px;
    right: -226px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 751px;
    height: 161px;
    top: -33px;
    right: -190px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 645px;
    height: 139px;
    top: -30px;
    right: -165px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 677px;
    height: 145px;
    top: -31px;
    right: -173px;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const Light = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius:50%;
  box-shadow: 0 0 430px 430px rgb(255 255 255 / 10%);
  ${props => props.theme.adaptive.index.xl} {
    box-shadow: 0 0 375px 375px rgb(255 255 255 / 10%);
  }
  ${props => props.theme.adaptive.index.lg} {
    box-shadow: 0 0 323px 323px rgb(255 255 255 / 10%);
  }
  ${props => props.theme.adaptive.index.md} {
    box-shadow: 0 0 275px 275px rgb(255 255 255 / 10%);
  }
  ${props => props.theme.adaptive.index.smd} {
    box-shadow: 0 0 270px 270px rgb(255 255 255 / 10%);
  }
`
