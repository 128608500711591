import React, { useState } from "react";
import styled from 'styled-components'
import formBg from '../../img/contacts/form-bg.svg'
import btnBg from '../../img/contacts/button-bg.svg'
import btnIcon from '../../img/contacts/button-icon.svg'

interface ErrorProps {
  error?: boolean
}


const Form: React.FunctionComponent = props => {
  const [isError, setIsError] = useState(false);

  return (
    <Container>
      <FieldGroup>
        <TopLine>
          <Label>
            Name*
          </Label>
          <Error error={true}>
            Incorrect name format
          </Error>
        </TopLine>
        <Input error={true} />
      </FieldGroup>
      <FieldGroup>
        <TopLine>
          <Label>
            Email*
          </Label>
          <Error>
            Incorrect email format
          </Error>
        </TopLine>
        <Input />
      </FieldGroup>
      <FieldGroup>
        <TopLine>
          <Label>
            Your Inquiry
          </Label>
          <Error>
            Incorrect email format
          </Error>
        </TopLine>
        <TextArea />
      </FieldGroup>
      <Button>
        Send Message
      </Button>
    </Container>
  )
}

export default Form

const Container = styled.div`
  position: relative;
  width: 460px;
  height: 477px;
  padding: 18px 32px 32px 65px;
  background: url("${formBg}") center no-repeat;
  background-size: contain;
  ${props => props.theme.adaptive.index.xl} {
    width: 402px;
    height: 417px;
    padding: 16px 28px 25px 57px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 345px;
    height: 357px;
    padding: 14px 24px 21px 49px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 319px;
    height: 331px;
    padding: 13px 19px 22px 45px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 297px;
    height: 307px;
    padding: 10px 21px 18px 42px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 267px;
    height: 287px;
    padding: 13px 13px 12px 42px;
  }
`

const FieldGroup = styled.div`
  width: 363px;
  margin-bottom: 25px;
  ${props => props.theme.adaptive.index.xl} {
    width: 317px;
    margin-bottom: 22px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 272px;
    margin-bottom: 18px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 252px;
    margin-bottom: 17px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 234px;
    margin-bottom: 14px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 212px;
    margin-bottom: 10px;
  }
`

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  ${props => props.theme.adaptive.index.xl} {
    margin-bottom: 7px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-bottom: 6px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-bottom: 5px;
  }
  ${props => props.theme.adaptive.index.smd} {;
    margin-bottom: 8px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-bottom: 8px;
  }
`

const Error = styled.div<ErrorProps>`
  display: block;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.05em;
  color: #FF2E2E;
  transition: 0.4s;
  opacity: ${({ error }) => error ? '1' : 0 };
  ${props => props.theme.adaptive.index.xl} {
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 16px;
  }
`

const Label = styled.div`
  font-weight: 500;
  font-size: 17.9813px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 16px;
    line-height: 21px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
  }
`

const Input = styled.input<ErrorProps>`
  width: 100%;
  height: 43px;
  padding: 12px 19px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #989898;
  background: #FFFFFF;
  border: 1px solid ${({ error }) => error ? 'rgba(255, 46, 46, 0.3)' : 'rgba(126, 135, 136, 0.3)'};
  transition: 0.4s;
  border-radius: 3px;
  outline: none;
  ${props => props.theme.adaptive.index.xl} {
    height: 37px;
    padding: 11px 16px;
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.lg} {
    height: 32px;
    padding: 8px 14px;
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.md} {
    height: 43px;
    padding: 7px 13px;
  }
  ${props => props.theme.adaptive.index.smd} {
    height: 27px;
    padding: 5px 12px;
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.sm} {
    height: 27px;
    padding: 5px 11px;
    font-size: 12px;
    line-height: 16px;
  }
`

const TextArea = styled.textarea<ErrorProps>`
  width: 100%;
  height: 107px;
  padding: 12px 19px;
  font-size: 14.712px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #989898;
  background: #FFFFFF;
  border: 1px solid ${({ error }) => error ? 'rgba(255, 46, 46, 0.3)' : 'rgba(126, 135, 136, 0.3)'};
  transition: 0.4s;
  border-radius: 3px;
  outline: none;
  resize: none;
  ${props => props.theme.adaptive.index.xl} {
    height: 94px;
    padding: 11px 16px;
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.lg} {
    height: 80px;
    padding: 9px 14px;
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.md} {
    height: 74px;
    padding: 8px 13px;
  }
  ${props => props.theme.adaptive.index.smd} {
    height: 68px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.sm} {
    height: 60px;
    padding: 8px 11px;
    font-size: 12px;
    line-height: 16px;
  }
`

const Button = styled.button`
  position: absolute;
  width: 403px;
  height: 59px;
  bottom: 28px;
  left: 24px;
  padding: 13px 96px 13px;
  text-align: right;
  font-size: 23px;
  line-height: 29px;
  letter-spacing: -0.045em;
  color: #FFFFFF;
  opacity: 0.9;
  background: url("${btnBg}") center no-repeat;
  background-size: cover;
  ${props => props.theme.adaptive.index.xl} {
    width: 353px;
    height: 51px;
    bottom: 25px;
    left: 21px;
    padding: 11px 84px 11px;
    font-size: 20px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 303px;
    height: 44px;
    bottom: 21px;
    left: 18px;
    padding: 9px 72px 9px;
    font-size: 17px;
    line-height: 21px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 280px;
    height: 41px;
    bottom: 21px;
    left: 18px;
    padding: 10px 67px 10px;
    font-size: 16px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 261px;
    height: 38px;
    bottom: 18px;
    left: 15px;
    padding: 10px 64px 10px;
    font-size: 15px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 239px;
    height: 38px;
    bottom: 18px;
    left: 15px;
    padding: 10px 56px 10px;
    font-size: 15px;
    line-height: 19px;
    background-size: contain;
  }
  &:before {
    content: '';
    position: absolute;
    width: 19px;
    height: 16px;
    top: 23px;
    left: 132px;
    background: url("${btnIcon}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 16px;
      height: 14px;
      top: 20px;
      left: 119px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 14px;
      height: 12px;
      top: 18px;
      left: 102px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 13px;
      height: 11px;
      top: 16px;
      left: 93px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 12px;
      height: 10px;
      top: 15px;
      left: 83px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 12px;
      height: 10px;
      top: 15px;
      left: 71px;
    }
  }
`
