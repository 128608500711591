import React from 'react'
import styled from 'styled-components'
import Header from "../Header";
import Footer from "../Footer";
import useWindowSize from "../../helpers/utils/useWindowSize";
import Mobile from "../Mobile";

interface Props {
  dark?: boolean
  index?: boolean
  children: React.ReactNode
}



export default function PageWrapper(props: Props) {
  const { width } = useWindowSize();

  return (
    <Wrapper>
      {
        (width < 800)
          ? <Mobile />
          : <>
              <Header index={props.index} dark={props.dark} />
              <Content>
                {props.children}
              </Content>
            </>

      }
      <Footer dark={props.dark} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`
