import React from 'react'
import { Props } from './Product'
import Product from './Product'
import predictionIcon from 'img/modals/products/prediction-icon.png'
import prediction from 'img/modals/products/prediction.svg'

function ProductPrediction(props: Props) {
  const {
    active,
    onClose,
    onNext,
  } = props

  const modalData = {
    title: 'preDiction markets',
    iconPath: predictionIcon,
    image: prediction,
    steps: [
      'Any tournament, free or paid, when originated offers <strong>prediction markets</strong> capabilities around its outcomes.',
      'Anyone having staked ZEUM gets access to bidding capabilities. <strong>Higher stake unlocks access to more exotic prediction mechanics.</strong>',
      'Bidders pool ZEUM and bid for a tournament outcome (e.g. winning parties or in-game occurrences).',
      `Once the tournament is concluded, <strong>90%</strong> of the staked ZEUM is distributed across the winning bidders, <strong>2.5%</strong> goes to Tournament Creator, <strong>2.5%</strong> goes to Game Devs, <strong>2.5%</strong> goes to Gamers participating in the tournament, <strong>2.5%</strong> ends up in Colizeum's ecosystem development fund.`,
    ]
  }
  return (
    <Product
      type="prediction-markets"
      active={active}
      content={modalData}
      onClose={onClose}
      onNext={onNext}
    />
  )
}

export default ProductPrediction
