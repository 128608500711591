import React from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import deckBg from '../../img/documents/deck-bg.png'
import arrowDeck from '../../img/documents/arrow-deck.svg'
import arrowPaper from '../../img/documents/arrow-paper.svg'
import arrowRight from '../../img/documents/arrow-right.svg'
import downloadArrow from '../../img/documents/download-arrow.svg'
import paperBg from '../../img/documents/paper-bg.svg'
import documentsBg from '../../img/documents/BG.png'

interface LinkProps {
  paper?: boolean
}

const Documents: React.FunctionComponent = props => (
    <Root>
      <PageWrapper>
        <Container>
          <Title>
            Documents
          </Title>
          <Cards>
            <DeckCard href={'/docs/deck.pdf'} target={'_blank'}>
              <DownloadLink/>
              <CardTitle>
                Investment Deck
              </CardTitle>
              <DeckBotLine>
                <DocDownload>
                  Download PDF
                </DocDownload>
                <DeckVersion>
                  30 Jul 2021, Version 1.0
                </DeckVersion>
              </DeckBotLine>
            </DeckCard>
            <LeftPanel>
              <PaperCard href={'/docs/wp.pdf'} target={'_blank'}>
                <DownloadLink/>
                <CardTitle>
                  White Paper <PaperVersion>V1</PaperVersion>
                </CardTitle>
                <PaperTextWrap>
                  <PaperTextBlock>
                    <PaperText>
                      “Cloud-based Tokenized Tournaments, Prediction Markets, MLM and a Global Attention Marketplace for the Gaming Industry”
                    </PaperText>
                    <DocDownload paper={true}>
                      Download PDF
                    </DocDownload>
                  </PaperTextBlock>
                </PaperTextWrap>
              </PaperCard>
              <BottomLinks>
                {/*<BottomLink href={'/'} target={'_blank'}>*/}
                {/*  Terms of use*/}
                {/*</BottomLink>*/}
                <BottomLink href={'https://dystopia.beetrootlab.com/uploads/pdf/beetroot-lab-privacy-policy.pdf'} target={'_blank'}>
                  privacy policy
                </BottomLink>
              </BottomLinks>
            </LeftPanel>
          </Cards>
        </Container>
      </PageWrapper>
    </Root>
  )

export default Documents

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 2052px;
    height: 848px;
    top: 156px;
    left: -100px;
    mix-blend-mode: soft-light;
    background: url("${documentsBg}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 1796px;
      height: 713px;
      top: 88px;
      left: -136px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 1539px;
      height: 611px;
      top: 117px;
      left: -75px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 1326px;
      height: 526px;
      top: 100px;
      left: -65px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 1325px;
      height: 526px;
      top: 101px;
      left: -65px;
    }
    @media screen and (max-width: 1100px) {
      content:  unset;
    }
  }
`

const Container = styled.div`
  width: 1600px;
  margin: auto;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 700px;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #353636;
  margin-bottom: 44px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
    margin-bottom: 39px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 29px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 20px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 19px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 26px;
  }
`

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
`

const DeckCard = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 784px;
  height: 546px;
  padding: 37px 105px 30px 56px;
  background: url("${deckBg}") center no-repeat;
  background-size: contain;
  outline: none;
  ${props => props.theme.adaptive.index.xl} {
    width: 686px;
    height: 478px;
    padding: 32px 86px 25px 49px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 588px;
    height: 409px;
    padding: 27px 74px 22px 42px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 506px;
    height: 351px;
    padding: 23px 63px 18px 36px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 524px;
    height: 364px;
    padding: 23px 66px 20px 36px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 357px;
    height: 248px;
    padding: 16px 45px 9px 25px;
  }
`

const CardTitle = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 25px;
    line-height: 34px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 22px;
    line-height: 30px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 19px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 19px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 16px;
    line-height: 21px;
  }
`

const DeckBotLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const DocDownload = styled.div<LinkProps>`
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.05em;
  color: ${({ paper }) => paper ? '#DF6DEF' : '#64E8FA'};
  width: 133px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 19px;
    line-height: 25px;
    width: 121px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 16px;
    line-height: 21px;
    width: 104px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 14px;
    line-height: 19px;
    width: 89px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 14px;
    line-height: 19px;
    width: 89px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
    width: 83px;
  }
`

const DeckVersion = styled.div`
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.05em;
  color: #CACACA;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 19px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 16px;
    line-height: 21px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
  }
`

const DownloadLink = styled.div<LinkProps>`
  position: absolute;
  top: 40px;
  right: ${({ paper }) => paper ? '30px' : '53px'};
  width: 37px;
  height: 37px;
  background: url("${downloadArrow}") center no-repeat;
  ${props => props.theme.adaptive.index.xl} {
    top: 35px;
    right: ${({ paper }) => paper ? '28px' : '38px'};
    width: 32px;
    height: 32px;
    background-size: cover;
  }
  ${props => props.theme.adaptive.index.lg} {
    top: 30px;
    right: ${({ paper }) => paper ? '26px' : '33px'};
    width: 27px;
    height: 27px;
  }
  ${props => props.theme.adaptive.index.md} {
    top: 25px;
    right: ${({ paper }) => paper ? '28px' : '27px'};
    width: 24px;
    height: 24px;
  }
  ${props => props.theme.adaptive.index.smd} {
    top: 26px;
    right: ${({ paper }) => paper ? '26px' : '29px'};
  }
  ${props => props.theme.adaptive.index.sm} {
    top: 18px;
    right: ${({ paper }) => paper ? '16px' : '19px'};
  }
`

const LeftPanel = styled.div`
  width: 691px;
  ${props => props.theme.adaptive.index.xl} {
    width: 605px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 519px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 447px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 463px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 316px;
  }
`


const PaperCard = styled.a`
  position: relative;
  display: block;
  width: 691px;
  height: 392px;
  padding: 38px 30px 56px 52px;
  background: url("${paperBg}") center no-repeat;
  background-size: contain;
  outline: none;
  ${props => props.theme.adaptive.index.xl} {
    width: 605px;
    height: 343px;
    padding: 33px 28px 48px 46px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 519px;
    height: 294px;
    padding: 27px 26px 42px 39px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 447px;
    height: 294px;
    padding: 24px 28px 36px 34px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 463px;
    height: 262px;
    padding: 25px 26px 37px 35px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 316px;
    height: 179px;
    padding: 17px 20px 21px 24px;
  }
`

const PaperVersion = styled.span`
  opacity: 0.2;
`

const PaperTextWrap = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 22px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 19px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 15px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 17px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 8px;
  }
`

const PaperTextBlock = styled.div`
  width: 335px;
  ${props => props.theme.adaptive.index.xl} {
    width: 293px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 251px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 216px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 224px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 153px;
  }
`

const PaperText = styled.div`
  text-align: left;
  font-style: italic;
  font-weight: 200;
  font-size: 24px;
  line-height: 31px;
  text-transform: uppercase;
  color: #353636;
  opacity: 0.9;
  margin-bottom: 20px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 20px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 17px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 11px;
  }
`

const BottomLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 41px 0 0 52px;
  ${props => props.theme.adaptive.index.xl} {
    margin: 36px 0 0 46px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin: 31px 0 0 40px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin: 27px 0 0 32px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin: 28px 0 0 36px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin: 19px 0 0 23px;
  }
`

const BottomLink = styled.a`
  position: relative;
  display: block;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #A3A3A3;
  margin-right: 121px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 25px;
    line-height: 34px;
    margin-right: 100px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 22px;
    line-height: 30px;
    margin-right: 83px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 19px;
    line-height: 25px;
    margin-right: 72px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 19px;
    line-height: 25px;
    margin-right: 74px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 16px;
    line-height: 21px;
    margin-right: 35px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 14px;
    right: -28px;
    top: 14px;
    background: url("${arrowRight}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 5px;
      height: 12px;
      right: -21px;
      top: 12px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 4px;
      height: 10px;
      right: -14px;
      top: 11px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 4px;
      height: 9px;
      right: -12px;
      top: 9px;
    }
    ${props => props.theme.adaptive.index.smd} {
      top: 9px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 3px;
      height: 6px;
      right: -10px;
      top: 7px;
    }
  }
`
