import React from "react";

const Apple: React.FunctionComponent = () => (
  <svg width="122" height="34" viewBox="0 0 122 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9753 15.7469C19.9432 12.1823 22.894 10.4481 23.029 10.3674C21.3578 7.93088 18.7676 7.59798 17.8573 7.57144C15.6819 7.3425 13.5716 8.87319 12.4634 8.87319C11.3331 8.87319 9.62656 7.59356 7.7873 7.63116C5.42048 7.66766 3.20629 9.03798 1.99191 11.1659C-0.514266 15.5047 1.35486 21.8807 3.75597 25.3878C4.95707 27.1054 6.36057 29.0232 8.19762 28.9558C9.99486 28.8817 10.6662 27.81 12.835 27.81C14.984 27.81 15.6144 28.9558 17.4879 28.9126C19.4168 28.8817 20.6312 27.1873 21.7902 25.4542C23.1783 23.4855 23.7357 21.5467 23.7578 21.4472C23.7125 21.4317 20.0118 20.0194 19.9753 15.7469Z" fill="#343838"/>
    <path d="M16.4366 5.26434C17.4032 4.05549 18.0646 2.41088 17.881 0.741943C16.482 0.803879 14.7323 1.70969 13.7247 2.89199C12.8333 3.93383 12.037 5.64148 12.2427 7.24738C13.8143 7.36462 15.4279 6.45439 16.4366 5.26434Z" fill="#343838"/>
    <g className={'text'}>
      <path d="M45.9854 28.71H43.4737L42.0978 24.3867H37.3155L36.0049 28.71H33.5596L38.2976 13.9915H41.2241L45.9854 28.71ZM41.6831 22.5729L40.4388 18.7296C40.3072 18.337 40.0606 17.4123 39.6967 15.9569H39.6525C39.5076 16.5829 39.2742 17.5075 38.9535 18.7296L37.7314 22.5729H41.6831Z" fill="#343838"/>
      <path d="M58.1697 23.2721C58.1697 25.077 57.6819 26.5038 56.7065 27.5511C55.8327 28.4835 54.7477 28.9491 53.4526 28.9491C52.0547 28.9491 51.0504 28.447 50.4388 27.4427H50.3946V33.0335H48.0366V21.5898C48.0366 20.4551 48.0067 19.2905 47.9492 18.096H50.023L50.1546 19.7782H50.1988C50.9852 18.5108 52.1785 17.8781 53.78 17.8781C55.032 17.8781 56.0771 18.3725 56.9133 19.3624C57.7516 20.3533 58.1697 21.6562 58.1697 23.2721ZM55.7675 23.3583C55.7675 22.3253 55.5352 21.4737 55.0685 20.8035C54.5586 20.1045 53.874 19.755 53.0158 19.755C52.434 19.755 51.9053 19.9497 51.4331 20.3334C50.9597 20.7205 50.65 21.226 50.5052 21.852C50.4322 22.1439 50.3957 22.3828 50.3957 22.5709V24.3404C50.3957 25.1124 50.6324 25.7639 51.1057 26.2958C51.5791 26.8278 52.194 27.0933 52.9505 27.0933C53.8386 27.0933 54.5299 26.7504 55.0242 26.0669C55.5197 25.3823 55.7675 24.4798 55.7675 23.3583Z" fill="#343838"/>
      <path d="M70.3766 23.2721C70.3766 25.077 69.8888 26.5038 68.9122 27.5511C68.0396 28.4835 66.9546 28.9491 65.6595 28.9491C64.2616 28.9491 63.2573 28.447 62.6468 27.4427H62.6026V33.0335H60.2446V21.5898C60.2446 20.4551 60.2147 19.2905 60.1572 18.096H62.231L62.3626 19.7782H62.4068C63.1921 18.5108 64.3854 17.8781 65.988 17.8781C67.2389 17.8781 68.284 18.3725 69.1224 19.3624C69.9574 20.3533 70.3766 21.6562 70.3766 23.2721ZM67.9744 23.3583C67.9744 22.3253 67.741 21.4737 67.2743 20.8035C66.7644 20.1045 66.082 19.755 65.2227 19.755C64.6398 19.755 64.1123 19.9497 63.6389 20.3334C63.1655 20.7205 62.8569 21.226 62.7121 21.852C62.6402 22.1439 62.6026 22.3828 62.6026 22.5709V24.3404C62.6026 25.1124 62.8393 25.7639 63.3104 26.2958C63.7838 26.8267 64.3987 27.0933 65.1574 27.0933C66.0455 27.0933 66.7368 26.7504 67.2311 26.0669C67.7266 25.3823 67.9744 24.4798 67.9744 23.3583Z" fill="#343838"/>
      <path d="M84.0246 24.5816C84.0246 25.8336 83.59 26.8522 82.7173 27.6385C81.7584 28.4979 80.4235 28.927 78.7081 28.927C77.1243 28.927 75.8547 28.6218 74.8936 28.0101L75.4399 26.0448C76.4751 26.6708 77.611 26.9849 78.8486 26.9849C79.7367 26.9849 80.4279 26.7836 80.9245 26.3832C81.4189 25.9829 81.6655 25.4453 81.6655 24.7751C81.6655 24.1779 81.462 23.6747 81.0539 23.2665C80.648 22.8584 79.9701 22.4791 79.0233 22.1285C76.4464 21.1674 75.159 19.7595 75.159 17.908C75.159 16.6981 75.6102 15.706 76.5138 14.934C77.4141 14.1609 78.6152 13.7749 80.1171 13.7749C81.4565 13.7749 82.5691 14.0083 83.4572 14.4739L82.8677 16.3961C82.0383 15.9449 81.1004 15.7193 80.0508 15.7193C79.2213 15.7193 78.5732 15.9239 78.1087 16.3309C77.716 16.6947 77.5192 17.1383 77.5192 17.6636C77.5192 18.2453 77.7437 18.7265 78.1949 19.1047C78.5876 19.4542 79.3009 19.8324 80.3361 20.2406C81.6025 20.7504 82.5326 21.3465 83.131 22.03C83.7271 22.7113 84.0246 23.5641 84.0246 24.5816Z" fill="#343838"/>
      <path d="M91.8208 19.8656H89.2217V25.0184C89.2217 26.329 89.6796 26.9838 90.5976 26.9838C91.019 26.9838 91.3685 26.9473 91.645 26.8743L91.7102 28.6649C91.2457 28.8385 90.6341 28.9259 89.8765 28.9259C88.9453 28.9259 88.2175 28.6416 87.6922 28.0743C87.169 27.5058 86.9058 26.5524 86.9058 25.2131V19.8634H85.3574V18.0938H86.9058V16.1506L89.2217 15.4516V18.0938H91.8208V19.8656Z" fill="#343838"/>
      <path d="M103.548 23.3152C103.548 24.9466 103.081 26.2859 102.15 27.3333C101.173 28.4116 99.8768 28.9491 98.2609 28.9491C96.7037 28.9491 95.4639 28.4326 94.5393 27.3996C93.6146 26.3666 93.1523 25.0627 93.1523 23.4911C93.1523 21.8465 93.6279 20.4994 94.5824 19.452C95.5346 18.4035 96.8198 17.8793 98.4357 17.8793C99.9929 17.8793 101.246 18.3958 102.192 19.4299C103.096 20.433 103.548 21.7281 103.548 23.3152ZM101.101 23.3915C101.101 22.4127 100.892 21.5733 100.468 20.8732C99.9741 20.026 99.2674 19.6035 98.3516 19.6035C97.4038 19.6035 96.6838 20.0271 96.1894 20.8732C95.7658 21.5744 95.5568 22.4271 95.5568 23.4358C95.5568 24.4146 95.7658 25.254 96.1894 25.953C96.6993 26.8002 97.4115 27.2227 98.3306 27.2227C99.2309 27.2227 99.9376 26.7913 100.447 25.9309C100.882 25.2175 101.101 24.3692 101.101 23.3915Z" fill="#343838"/>
      <path d="M111.212 20.1698C110.979 20.1266 110.73 20.1045 110.469 20.1045C109.64 20.1045 108.998 20.4175 108.547 21.0446C108.154 21.5976 107.957 22.2966 107.957 23.1404V28.7091H105.601L105.623 21.4383C105.623 20.2151 105.593 19.1014 105.534 18.0971H107.588L107.674 20.1277H107.74C107.988 19.4298 108.381 18.868 108.919 18.4466C109.444 18.0673 110.011 17.8781 110.623 17.8781C110.841 17.8781 111.038 17.8936 111.212 17.9213V20.1698Z" fill="#343838"/>
      <path d="M121.758 22.9005C121.758 23.323 121.73 23.6791 121.672 23.97H114.598C114.626 25.0185 114.967 25.8203 115.624 26.3733C116.22 26.8677 116.991 27.1154 117.938 27.1154C118.985 27.1154 119.941 26.9484 120.8 26.6133L121.17 28.2502C120.165 28.6881 118.98 28.906 117.612 28.906C115.966 28.906 114.674 28.4216 113.734 27.4539C112.796 26.4861 112.326 25.1866 112.326 23.5563C112.326 21.956 112.763 20.6233 113.638 19.5604C114.554 18.4256 115.791 17.8583 117.348 17.8583C118.878 17.8583 120.036 18.4256 120.822 19.5604C121.445 20.4618 121.758 21.5766 121.758 22.9005ZM119.51 22.2889C119.525 21.5899 119.371 20.986 119.052 20.4762C118.644 19.8203 118.016 19.4929 117.173 19.4929C116.402 19.4929 115.775 19.8126 115.296 20.454C114.903 20.9639 114.67 21.5755 114.598 22.2878H119.51V22.2889Z" fill="#343838"/>
      <path d="M40.9026 4.93587C40.9026 6.23762 40.5122 7.21753 39.7324 7.87559C39.0102 8.48278 37.9839 8.78693 36.6545 8.78693C35.9953 8.78693 35.4312 8.75817 34.959 8.70066V1.58804C35.575 1.4885 36.2386 1.43762 36.9553 1.43762C38.2217 1.43762 39.1761 1.71301 39.8198 2.2638C40.5409 2.88647 40.9026 3.77679 40.9026 4.93587ZM39.6805 4.96794C39.6805 4.12407 39.4571 3.47707 39.0102 3.02582C38.5634 2.57569 37.9109 2.35006 37.0515 2.35006C36.6865 2.35006 36.3758 2.3744 36.1181 2.42527V7.83246C36.2607 7.85458 36.5217 7.86453 36.9011 7.86453C37.7881 7.86453 38.4727 7.6179 38.9549 7.12463C39.4371 6.63135 39.6805 5.91246 39.6805 4.96794Z" fill="#343838"/>
      <path d="M47.3827 6.07278C47.3827 6.87463 47.1538 7.53159 46.6959 8.04698C46.2159 8.57675 45.58 8.84108 44.7859 8.84108C44.0205 8.84108 43.4111 8.58781 42.9566 8.07905C42.5031 7.5714 42.2764 6.93103 42.2764 6.15905C42.2764 5.35168 42.5097 4.68919 42.9787 4.17491C43.4476 3.66062 44.078 3.40292 44.8721 3.40292C45.6375 3.40292 46.2524 3.6562 46.718 4.16384C47.1604 4.65712 47.3827 5.29417 47.3827 6.07278ZM46.1805 6.11039C46.1805 5.62928 46.0766 5.21675 45.8697 4.87278C45.6264 4.45693 45.2802 4.24901 44.8301 4.24901C44.3645 4.24901 44.0106 4.45693 43.7672 4.87278C43.5593 5.21675 43.4565 5.63592 43.4565 6.1314C43.4565 6.61251 43.5604 7.02504 43.7672 7.36901C44.0183 7.78486 44.3678 7.99278 44.819 7.99278C45.2614 7.99278 45.6087 7.78154 45.8587 7.35795C46.0732 7.00735 46.1805 6.5915 46.1805 6.11039Z" fill="#343838"/>
      <path d="M56.0715 3.50912L54.4402 8.72276H53.3784L52.7026 6.4588C52.5312 5.89364 52.3919 5.3318 52.2835 4.77438H52.2625C52.1618 5.34728 52.0225 5.90802 51.8433 6.4588L51.1255 8.72276H50.0516L48.5176 3.50912H49.7087L50.2982 5.98765C50.4409 6.57383 50.5581 7.13235 50.6521 7.66101H50.6732C50.7594 7.22525 50.9021 6.67005 51.1034 5.99871L51.8433 3.51023H52.7878L53.4967 5.94562C53.6682 6.53954 53.8075 7.11134 53.9148 7.66212H53.9469C54.0254 7.12571 54.1438 6.55392 54.3008 5.94562L54.9334 3.51023H56.0715V3.50912Z" fill="#343838"/>
      <path d="M62.0797 8.72276H60.9206V5.73659C60.9206 4.8164 60.5711 4.35631 59.8699 4.35631C59.526 4.35631 59.2484 4.48239 59.0327 4.73566C58.8193 4.98894 58.7109 5.28755 58.7109 5.6293V8.72166H57.5518V4.99889C57.5518 4.54101 57.5374 4.04442 57.5098 3.50691H58.5284L58.5826 4.32202H58.6147C58.7496 4.06875 58.9509 3.85972 59.2152 3.69272C59.5293 3.49806 59.881 3.39963 60.2659 3.39963C60.7525 3.39963 61.1573 3.55668 61.4792 3.87189C61.8795 4.25788 62.0797 4.8341 62.0797 5.59944V8.72276Z" fill="#343838"/>
      <path d="M65.2761 8.72275H64.1182V1.11685H65.2761V8.72275Z" fill="#343838"/>
      <path d="M72.1005 6.07278C72.1005 6.87463 71.8716 7.53159 71.4137 8.04698C70.9337 8.57675 70.2966 8.84108 69.5036 8.84108C68.7372 8.84108 68.1278 8.58781 67.6743 8.07905C67.2209 7.5714 66.9941 6.93103 66.9941 6.15905C66.9941 5.35168 67.2275 4.68919 67.6964 4.17491C68.1654 3.66062 68.7958 3.40292 69.5888 3.40292C70.3552 3.40292 70.9691 3.6562 71.4358 4.16384C71.8782 4.65712 72.1005 5.29417 72.1005 6.07278ZM70.8972 6.11039C70.8972 5.62928 70.7932 5.21675 70.5864 4.87278C70.3442 4.45693 69.9969 4.24901 69.5479 4.24901C69.0811 4.24901 68.7272 4.45693 68.485 4.87278C68.2771 5.21675 68.1742 5.63592 68.1742 6.1314C68.1742 6.61251 68.2782 7.02504 68.485 7.36901C68.7361 7.78486 69.0856 7.99278 69.5368 7.99278C69.9792 7.99278 70.3254 7.78154 70.5753 7.35795C70.791 7.00735 70.8972 6.5915 70.8972 6.11039Z" fill="#343838"/>
      <path d="M77.7099 8.72274H76.6691L76.5829 8.12219H76.5508C76.1947 8.60108 75.687 8.84108 75.0278 8.84108C74.5357 8.84108 74.1375 8.68292 73.8378 8.36882C73.5657 8.08348 73.4297 7.72845 73.4297 7.30707C73.4297 6.67002 73.6951 6.18449 74.2293 5.84827C74.7624 5.51205 75.5123 5.34725 76.4778 5.355V5.25767C76.4778 4.57085 76.1172 4.22799 75.395 4.22799C74.8807 4.22799 74.4273 4.35739 74.0358 4.61398L73.8002 3.85306C74.2846 3.55334 74.883 3.40292 75.5886 3.40292C76.9512 3.40292 77.6347 4.12182 77.6347 5.55961V7.47961C77.6347 8.00053 77.6601 8.41527 77.7099 8.72274ZM76.5066 6.93103V6.12698C75.228 6.10486 74.5888 6.45546 74.5888 7.17767C74.5888 7.44974 74.6618 7.65325 74.8111 7.78928C74.9604 7.92532 75.1506 7.99278 75.3773 7.99278C75.6317 7.99278 75.8695 7.91205 76.0863 7.75168C76.3042 7.5902 76.438 7.3856 76.4878 7.13454C76.4999 7.07813 76.5066 7.00956 76.5066 6.93103Z" fill="#343838"/>
      <path d="M84.2963 8.72275H83.2677L83.2135 7.88551H83.1815C82.853 8.52257 82.2933 8.84109 81.507 8.84109C80.8788 8.84109 80.3556 8.59446 79.9409 8.10118C79.5262 7.60791 79.3193 6.96754 79.3193 6.18118C79.3193 5.33731 79.5439 4.65381 79.9951 4.13178C80.432 3.64515 80.9673 3.40183 81.6043 3.40183C82.3044 3.40183 82.7944 3.6374 83.0731 4.10966H83.0952V1.11685H84.2554V7.31814C84.2554 7.82579 84.2686 8.29363 84.2963 8.72275ZM83.0952 6.52404V5.65473C83.0952 5.50432 83.0841 5.38266 83.0631 5.28976C82.9979 5.01105 82.8574 4.77658 82.6439 4.58745C82.4283 4.39833 82.1684 4.30321 81.8686 4.30321C81.4362 4.30321 81.0978 4.47464 80.8489 4.8186C80.6023 5.16257 80.4773 5.60164 80.4773 6.13805C80.4773 6.65344 80.5956 7.07151 80.8334 7.39335C81.0845 7.73621 81.4229 7.90763 81.8465 7.90763C82.227 7.90763 82.5311 7.76496 82.7623 7.47851C82.9857 7.21418 83.0952 6.89565 83.0952 6.52404Z" fill="#343838"/>
      <path d="M94.2089 6.07278C94.2089 6.87463 93.98 7.53159 93.5221 8.04698C93.0421 8.57675 92.4072 8.84108 91.612 8.84108C90.8478 8.84108 90.2384 8.58781 89.7827 8.07905C89.3293 7.5714 89.1025 6.93103 89.1025 6.15905C89.1025 5.35168 89.3359 4.68919 89.8048 4.17491C90.2738 3.66062 90.9042 3.40292 91.6994 3.40292C92.4636 3.40292 93.0797 3.6562 93.5442 4.16384C93.9866 4.65712 94.2089 5.29417 94.2089 6.07278ZM93.0078 6.11039C93.0078 5.62928 92.9038 5.21675 92.697 4.87278C92.4526 4.45693 92.1075 4.24901 91.6563 4.24901C91.1918 4.24901 90.8378 4.45693 90.5934 4.87278C90.3855 5.21675 90.2826 5.63592 90.2826 6.1314C90.2826 6.61251 90.3866 7.02504 90.5934 7.36901C90.8445 7.78486 91.194 7.99278 91.6452 7.99278C92.0876 7.99278 92.436 7.78154 92.686 7.35795C92.8994 7.00735 93.0078 6.5915 93.0078 6.11039Z" fill="#343838"/>
      <path d="M100.441 8.72276H99.2829V5.73659C99.2829 4.8164 98.9335 4.35631 98.2312 4.35631C97.8872 4.35631 97.6096 4.48239 97.395 4.73566C97.1805 4.98894 97.0732 5.28755 97.0732 5.6293V8.72166H95.913V4.99889C95.913 4.54101 95.8997 4.04442 95.8721 3.50691H96.8896L96.9438 4.32202H96.9759C97.1119 4.06875 97.3132 3.85972 97.5764 3.69272C97.8916 3.49806 98.2422 3.39963 98.6282 3.39963C99.1137 3.39963 99.5185 3.55668 99.8404 3.87189C100.242 4.25788 100.441 4.8341 100.441 5.59944V8.72276Z" fill="#343838"/>
      <path d="M108.242 4.37733H106.965V6.91005C106.965 7.55373 107.192 7.87558 107.641 7.87558C107.849 7.87558 108.022 7.85788 108.158 7.82138L108.188 8.70065C107.959 8.78691 107.658 8.83005 107.287 8.83005C106.829 8.83005 106.473 8.69069 106.216 8.41198C105.957 8.13327 105.828 7.66433 105.828 7.00627V4.37733H105.066V3.50913H105.828V2.55355L106.964 2.21069V3.50802H108.241V4.37733H108.242Z" fill="#343838"/>
      <path d="M114.378 8.72275H113.217V5.7587C113.217 4.82413 112.868 4.3563 112.168 4.3563C111.63 4.3563 111.263 4.62727 111.062 5.1692C111.027 5.28312 111.008 5.42247 111.008 5.58616V8.72164H109.85V1.11685H111.008V4.25897H111.03C111.395 3.68717 111.918 3.40183 112.596 3.40183C113.076 3.40183 113.473 3.55888 113.788 3.87409C114.181 4.26671 114.378 4.85068 114.378 5.62266V8.72275Z" fill="#343838"/>
      <path d="M120.707 5.86932C120.707 6.07724 120.692 6.25199 120.664 6.39466H117.188C117.204 6.91006 117.37 7.30268 117.691 7.57476C117.986 7.81807 118.365 7.93973 118.83 7.93973C119.344 7.93973 119.813 7.85789 120.235 7.6931L120.417 8.49826C119.922 8.71282 119.34 8.8201 118.667 8.8201C117.86 8.8201 117.224 8.58231 116.764 8.10674C116.301 7.63116 116.072 6.99301 116.072 6.19227C116.072 5.40591 116.286 4.75116 116.716 4.22913C117.165 3.67171 117.772 3.39301 118.539 3.39301C119.288 3.39301 119.858 3.67171 120.243 4.22913C120.554 4.67153 120.707 5.219 120.707 5.86932ZM119.601 5.56959C119.61 5.22563 119.534 4.92923 119.377 4.67927C119.176 4.35743 118.869 4.19595 118.455 4.19595C118.076 4.19595 117.768 4.35301 117.532 4.66821C117.34 4.91927 117.226 5.219 117.188 5.56959H119.601Z" fill="#343838"/>
    </g>
    </svg>

)

export default Apple
