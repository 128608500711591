import React from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import bgImg from '../../img/index/background.png'
import titleImg from '../../img/index/title-img.svg'
import btnRightImg from '../../img/index/right-btn.svg'
import btnLeftImg from '../../img/index/left-btn.svg'
import triangleImg from '../../img/index/triangle.svg'
import gameImg from '../../img/index/game-logo.svg'
import cardanoImg from '../../img/index/cardano-img.svg'
import youtubeImg from '../../img/index/youtube-icon.svg'
import ConorPhoto from '../../img/conor/ConorPhoto.svg'
import bigConor from '../../img/conor/big-conor.png'
import linkList from '../../store/downloadLinks'
import occamLogo from '../../img/index/occam-logo.svg'
import { Link  } from "react-router-dom";

interface LinkWProps {
  padding: number
}

const Index: React.FunctionComponent = () => (
    <Root>
      <PageWrapper index={true}>
          <Content>
            <Left>
              <TitleWrap>
                <Title src={titleImg} />
                <Built>
                  Built for Cardano
                </Built>
              </TitleWrap>
              <Description>
                Turning Digital Funnel into attention-centric ecosystem
              </Description>
              <ButtonWrap>
                <Button>
                  <Link to="/reasons">
                    Pitch me now!
                  </Link>
                </Button>
                <Button right={true}>
                  <Link to="/products">
                    ZEUM Products
                  </Link>
                </Button>
              </ButtonWrap>
              <ByOccam>
                <Text>
                  Incubated by
                </Text>
                <OccamWrap href={'https://occam.fi'} target={'_blank'}>
                  <OccamLogo src={occamLogo}/>
                </OccamWrap>
              </ByOccam>
              <GamePanel>
                <GameLogo src={gameImg} />
                <DownloadLinks>
                  {linkList.map((link, idx) => {
                    return (
                      <LinkWrap
                        padding={link.padding}
                        href={link.link}
                        target={'_blank'}
                      >
                        <DownloadLink
                          height={link.height}
                        >
                          {link.getIcon()}
                        </DownloadLink>
                      </LinkWrap>
                    )
                  })}
                </DownloadLinks>
              </GamePanel>
            </Left>
            <Right>
              <ConorLinkWrap>
                <ConorLink>
                  <LinkHead>
                    <LinkText>
                      Show is run by
                      <span>Conor McGregor</span>
                    </LinkText>
                    <Photo src={ConorPhoto} />
                  </LinkHead>
                  <Border />
                  <YoutubeWrap href={'https://youtu.be/nNR2LmVKamY'} target={'_blank'}>
                    <YoutubeIcon src={youtubeImg} />
                    <YoutubeText>
                      Fight for your future today!
                    </YoutubeText>
                  </YoutubeWrap>
                </ConorLink>
              </ConorLinkWrap>
              <RightInfoWrap>
                <InfoUsers>
                  Initial audience spans accross <More>{'>'}</More><Count>1 000 000 users</Count>
                </InfoUsers>
                <InfoValue>
                  Product is built by the team with <Count>$1 000 000 000</Count><Plus>+</Plus> in exits value
                </InfoValue>
              </RightInfoWrap>
            </Right>
          </Content>
      </PageWrapper>
    </Root>
)

export default Index

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`

const Content = styled.div`
  width: 1600px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 694px;
  }
`

const Left = styled.div`
  position: relative;
  width: 748px;
  ${props => props.theme.adaptive.index.xl} {
    width: 629px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 514px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 465px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 497px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 380px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 702px;
  height: 193px;
  margin-top: 19px;
  ${props => props.theme.adaptive.index.xl} {
    width: 628px;
    height: 172px;
    margin-top: 20px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 526px;
    height: 145px;
    margin-top: 19px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 453px;
    height: 124px;
    margin-top: 48px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 376px;
    height: 131px;
    margin-top: 13px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 370px;
    height: 101px;
    margin-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1552px;
    height: 1777px;
    left: -443px;
    top: -394px;
    background: url("${bgImg}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 1349px;
      height: 1545px;
      left: -385px;
      top: -425px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 1164px;
      height: 1334px;
      left: -332px;
      top: -350px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 1009px;
      height: 1156px;
      left: -292px;
      top: -322px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 877px;
      height: 1148px;
      left: -213px;
      top: -300px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 759px;
      height: 867px;
      left: -229px;
      top: -230px;
    }
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
`

const Built = styled.div`
  position: absolute;
  width: 89px;
  height: 47px;
  top: 10px;
  right: -89px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.05em;
  color: #424445;
  ${props => props.theme.adaptive.index.xl} {
    width: 70px;
    height: 41px;
    top: 4px;
    right: -70px;
    font-size: 16px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 54px;
    height: 33px;
    top: 6px;
    right: -62px;
    font-size: 13px;
    line-height: 16px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 54px;
    height: 30px;
    top: 7px;
    right: -61px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 60px;
    height: 32px;
    top: 6px;
    right: -69px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 44px;
    height: 24px;
    top: 2px;
    right: -45px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 73px;
    height: 67px;
    top: -11px;
    left: 40px;
    background: url("${cardanoImg}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 64px;
      height: 59px;
      top: -10px;
      left: 35px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 55px;
      height: 50px;
      top: -8px;
      left: 30px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 47px;
      height: 43px;
      top: -7px;
      left: 32px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 49px;
      height: 45px;
      top: -7px;
      left: 27px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 39px;
      height: 35px;
      top: -6px;
      left: 26px;
    }
  }
`


const Title = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
`

const Description = styled.div`
  width: 100%;
  margin-top: 31px;
  font-weight: 200;
  font-size: 42px;
  line-height: 51px;
  text-transform: uppercase;
  color: #353636;
  opacity: 0.7;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 26px;
    font-size: 38px;
    line-height: 45px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 22px;
    font-size: 31px;
    line-height: 38px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 20px;
    font-size: 28px;
    line-height: 33px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 16px;
    font-size: 30px;
    line-height: 35px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 17px;
    font-size: 23px;
    line-height: 27px;
  }
`

const ButtonWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 40px;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 32px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 26px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 28px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 18px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 23px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 49px;
    height: 23px;
    top: 0;
    left: 308px;
    background: url("${triangleImg}") center no-repeat;
    background-size: cover;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 43px;
      height: 20px;
      left: 267px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 37px;
      height: 17px;
      left: 231px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 33px;
      height: 16px;
      left: 210px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 33px;
      height: 16px;
      left: 210px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 27px;
      height: 13px;
      left: 168px;
    }
  }
`

interface Props {
  right?: boolean
}


const Button = styled.div<Props>`
  position: relative;
  margin-right: 29px;
  z-index: 3;
  ${props => props.theme.adaptive.index.xl} {
    margin-right: 26px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-right: 21px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-right: 20px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-right: 20px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-right: 16px;
  }
  a {
    display: block;
    width: 317px;
    height: 73px;
    outline: none;
    padding: 18px;
    background: ${({right}) => right ? `url(${btnRightImg})` : `url(${btnLeftImg})`};
    background-size: cover;
    color: ${({right}) => right ? '#343838' : '#FFFFFF'};
    opacity: 0.9;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    ${props => props.theme.adaptive.index.xl} {
      width: 277px;
      height: 64px;
      padding: 17px;
      font-size: 25px;
      line-height: 31px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 238px;
      height: 55px;
      padding: 14px;
      font-size: 21px;
      line-height: 26px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 216px;
      height: 50px;
      padding: 13px;
      font-size: 19px;
      line-height: 24px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 216px;
      height: 50px;
      padding: 13px;
      font-size: 19px;
      line-height: 24px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 173px;
      height: 40px;
      padding: 10px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &:last-child {
    margin: 0;
  }
`

const GamePanel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 103px;
  z-index: 3;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 80px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 125px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 116px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 48px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 120px;
  }
`

const GameLogo = styled.img`
  display: block;
  width: 257px;
  height: 61px;
  ${props => props.theme.adaptive.index.xl} {
    width: 225px;
    height: 53px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 193px;
    height: 45px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 194px;
    height: 46px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 175px;
    height: 41px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 141px;
    height: 33px;
  }
`

const DownloadLinks = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-left: 33px;
  ${props => props.theme.adaptive.index.xl} {
    margin-left: 29px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-left: 25px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-left: 24px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-left: 23px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-left: 17px;
  }
`

const LinkWrap = styled.a<LinkWProps>`
  display: flex;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-right: 21px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  padding: ${({ padding }) => padding ? `0 ${padding}px` : '0' };
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  transition: 0.4s;
  overflow: hidden;
  ${props => props.theme.adaptive.index.xl} {
    width: 39px;
    height: 39px;
    margin-right: 18px;
    box-shadow: 0 0 19px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: ${({ padding }) => padding ? `0 ${padding-1}px` : '0' };
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 33px;
    height: 33px;
    margin-right: 16px;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.04);
    padding: ${({ padding }) => padding ? `0 ${padding-1}px` : '0' };
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 29px;
    height: 29px;
    margin-right: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.04);
    padding: ${({ padding }) => padding ? `0 ${padding-2}px` : '0' };
    border-radius: 3px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 29px;
    height: 29px;
    margin-right: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.04);
    padding: ${({ padding }) => padding ? `0 ${padding-2}px` : '0' };
    border-radius: 3px;
  }
  &:last-child {
    margin-right: 0;
  }
  & .text {
    transition: 0.4s;
    opacity: 0;
  }
  &:hover {
    width: 159px;
    ${props => props.theme.adaptive.index.xl} {
      width: 139px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 119px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 108px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 108px;
    }
    & .text {
      opacity: 1;
    }
  }
`

interface LinkProps {
  height: string
}

const DownloadLink = styled.div<LinkProps>`
  display: flex;
  width: 159px;
  justify-content: center;
  align-items: center;
  ${props => props.theme.adaptive.index.xl} {
    width: 139px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 119px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 108px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 108px;
  }
  svg{
    display: block;
    height: ${({ height }) => height ? height : '100%' };
    width: 120px;
    ${props => props.theme.adaptive.index.xl} {
      width: 110px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 94px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 85px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 85px;
    }
  }
`

const Right = styled.div`
  position: relative;
  width: 718px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.theme.adaptive.index.xl} {
    width: 628px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 538px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 467px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 482px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 311px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1101px;
    height: 869px;
    bottom: -142px;
    left: -296px;
    background: url("${bigConor}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      background-size: cover;
      width: 765px;
      height: 767px;
      left: -195px;
      bottom: -80px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 660px;
      height: 662px;
      left: -180px;
      bottom: -35px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 663px;
      height: 664px;
      left: -180px;
      bottom: -50px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 711px;
      height: 710px;
      left: -180px;
      bottom: -185px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 468px;
      height: 468px;
      left: -95px;
      top: -15px;
    }
  }
`

const ConorLinkWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 40px;
  }
`

const ConorLink = styled.div`
  position: relative;
  width: 299px;
  height: 137px;
  padding: 12px 22px 15px 23px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  ${props => props.theme.adaptive.index.xl} {
    width: 262px;
    height: 120px;
    padding: 11px 17px 15px 21px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.03), 0 1.75px 3.5px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 224px;
    height: 103px;
    padding: 9px 13px 13px 18px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.03), 0 1.75px 3.5px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 196px;
    height: 90px;
    padding: 8px 13px 10px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03), 0 1.75px 3.5px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 193px;
    height: 88px;
    padding: 8px 12px 10px 15px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 94px;
    height: 72px;
    padding: 0;
    background: transparent;
    border: 0;
    box-shadow: unset;
    border-radius: 0;
  }
`

const LinkHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LinkText = styled.div`
  width: 193px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.045em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    width: 164px;
    font-size: 16px;
    line-height: 24px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 142px;
    font-size: 14px;
    line-height: 21px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 127px;
    font-size: 12px;
    line-height: 18px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 125px;
    font-size: 12px;
    line-height: 18px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 100%;
    font-size: 15px;
    line-height: 21px;
  }
  span {
    display: block;
    font-weight: 600;
    font-size: 23px;
    ${props => props.theme.adaptive.index.xl} {
      font-size: 21px;
    }
    ${props => props.theme.adaptive.index.lg} {
      font-size: 18px;
    }
    ${props => props.theme.adaptive.index.md} {
      font-size: 15px;
    }
    ${props => props.theme.adaptive.index.smd} {
      font-size: 14px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 100%;
      font-size: 21px;
      line-height: 26px;
    }
  }
`

const Photo = styled.img`
  display: block;
  width: 54px;
  height: 62px;
  ${props => props.theme.adaptive.index.xl} {
    width: 47px;
    height: 54px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 41px;
    height: 47px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 35px;
    height: 41px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 35px;
    height: 41px;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  margin: 6px 0 11px;
  background: rgba(0, 0, 0, 0.07);
  ${props => props.theme.adaptive.index.xl} {
    margin: 5px 0 6px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin: 4px 0 4px;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const YoutubeWrap = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.adaptive.index.sm} {
    position: absolute;
    right: 0;
    top: 29px;
    width: 27px;
    height: 17px;
    display: block;
  }
`

const YoutubeIcon = styled.img`
  display: block;
  width: 33px;
  height: 21px;
  ${props => props.theme.adaptive.index.xl} {
    width: 29px;
    height: 18px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 24px;
    height: 16px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 21px;
    height: 14px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 21px;
    height: 14px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 27px;
    height: 17px;
  }
`

const YoutubeText = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  text-align: right;
  letter-spacing: -0.045em;
  color: #FB1716;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 16px;
    line-height: 25px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 13px;
    line-height: 22px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 12px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 12px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.sm} {
    display: none;
  }
`

const RightInfoWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  ${props => props.theme.adaptive.index.sm} {
    width: 349px;
    left: -38px;
  }
`

const InfoUsers = styled.div`
  width: 237px;
  height: 52px;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.045em;
  color: #353636;
  margin-right: 81px;
  ${props => props.theme.adaptive.index.xl} {
    width: 203px;
    height: 49px;
    font-size: 18px;
    line-height: 24px;
    margin-right: 75px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 173px;
    height: 39px;
    font-size: 15px;
    line-height: 20px;
    margin-right: 65px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 167px;
    height: 36px;
    font-size: 13px;
    line-height: 17px;
    margin-right: 53px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 166px;
    height: 34px;
    font-size: 15px;
    line-height: 20px;
    margin-right: 41px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 148px;
    height: 31px;
    font-size: 13px;
    line-height: 17px;
    margin-right: 20px;
  }
`

const More = styled.span`
  font-weight: bold;
  color: #DF6DEF;
`

const Count = styled.span`
  font-weight: bold;
`

const InfoValue = styled.div`
  width: 286px;
  height: 52px;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.045em;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    width: 257px;
    height: 49px;
    font-size: 18px;
    line-height: 24px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 214px;
    height: 39px;
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 211px;
    height: 36px;
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 185px;
    height: 36px;
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 185px;
    height: 36px;
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 183px;
    height: 31px;
    font-size: 13px;
    line-height: 17px;
  }
`

const Plus = styled.span`
  font-weight: bold;
  color: #FFCA6A;
`

const ByOccam = styled.div`
  position: relative;
  margin-top: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 42px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 34px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 31px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 33px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 25px;
  }
`

const OccamWrap = styled.a`
  display: block;
  padding: 15px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  outline: none;
  ${props => props.theme.adaptive.index.xl} {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03);
    padding: 14px 19px;
    border-radius: 7px;
  }
  ${props => props.theme.adaptive.index.lg} {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.03), 0 1.47467px 2.94933px rgba(0, 0, 0, 0.03);
    padding: 11px 15px;
    border-radius: 6px;
  }
  ${props => props.theme.adaptive.index.md} {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03), 0 1.33115px 2.6623px rgba(0, 0, 0, 0.03);
    padding: 10px 14px;
    border-radius: 5px;
  }
  ${props => props.theme.adaptive.index.smd} {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.03), 0 1.432px 2.864px rgba(0, 0, 0, 0.03);
    padding: 11px 15px;
    border-radius: 6px;
  }
  ${props => props.theme.adaptive.index.sm} {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.03), 0 1px 25px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 9px 12px;
  }
  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`

const OccamLogo = styled.img`
  display: block;
  width: 146px;
  height: 17px;
  ${props => props.theme.adaptive.index.xl} {
    width: 132px;
    height: 15px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 108px;
    height: 13px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 97px;
    height: 11px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 104px;
    height: 12px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 79px;
    height: 9px;
  }
`

const Text = styled.div`
  width: 162px;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 51px;
  color: #353636;
  ${props => props.theme.adaptive.index.xl} {
    width: 147px;
    font-size: 19px;
    line-height: 46px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 119px;
    font-size: 16px;
    line-height: 38px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 108px;
    font-size: 14px;
    line-height: 34px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 116px;
    font-size: 15px;
    line-height: 36px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 88px;
    font-size: 12px;
    line-height: 28px;
  }
`
