import React from 'react'
import { Props } from './Product'
import Product from './Product'
import mlmIcon from 'img/modals/products/mlm-icon.png'
import mlmImage from 'img/modals/products/mlm.svg'

function ProductAttention(props: Props) {
  const {
    active,
    onClose,
    onNext,
  } = props

  const modalData = {
    title: 'MLM As a Service',
    shortDesc: 'In respect to the Multi-Level-Marketing (MLM) and Referral incentives Colizeum offers the following ZEUM staking incentives:',
    iconPath: mlmIcon,
    image: mlmImage,
    steps: [
      'When staked, ZEUM enables generation of a referral link. <strong>The larger the stake, the better the better referral rates are available.</strong>',
      `
        <p>A particular dimension of staking incentives is the referral tree depth. There are <strong>three tiers</strong> one can get on and every higher tier requires a larger ZEUM stake while offering a deeper referral tree.</p>
        <p>In other words, <strong>the depth of the referral tree changes in line with the amount of tokens staked.</strong> Higher tiers also have time staking requirements.</p>
      `,
      `
        <p>There’s also an interesting redistribution mechanism in play in the Colizeum MLM service. The idea is simple: all the referral payouts generated by someone at the tree depths higher than one’s tier allows for, are collected into a special pool that:</p>
        <span>
          <p>(a) airdrops 30% of the funds collected across the other stakers on a pro-rata basis.</p>
          <p>(b) airdrops the remaining 70% to all stakers who have generated a referral link and have higher tier. Overall, this system should incentivize the movement up the tier ladder.</p>
        </span>
      `,
    ]
  }
  return (
    <Product
      type="mlm"
      active={active}
      content={modalData}
      onClose={onClose}
      onNext={onNext}
    />
  )
}

export default ProductAttention
