import React from "react";

interface Color {
  fill: string
}

const Arrow: React.FunctionComponent<Color> = props => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 10L5 5.5L1 0.999999" stroke={props.fill} strokeWidth="1.5"/>
  </svg>
)

export default Arrow
