import React from "react";
import styled from 'styled-components'
import PageWrapper from "../PageWrapper/PageWrapper";
import photoWrap from '../../img/contacts/photo-wrap.svg'
import photoManager from '../../img/contacts/manager-photo.png'
import bg from '../../img/contacts/BG.png'
import mask from '../../img/contacts/bg-mask.svg'
import TelegramIcon from "../Icons/contacts/Telegram";
import Form from "./Form";

const Contacts: React.FunctionComponent = props => (
  <Root>
    <PageWrapper>
      <Container>
        <Title>
          contact us
        </Title>
        <Content>
          <Info>
            <Manager>
              <PhotoFrame />
              <Photo />
              <ManagerInfo>
                <ManagerName>
                  Davis Ziedins
                </ManagerName>
                <ManagerPosition>
                  General Manager
                </ManagerPosition>
              </ManagerInfo>
            </Manager>
            <ContactsLine>
              <Email href={'mailto:davis@colizeum.io'} target={'_blank'}>
                davis@colizeum.io
              </Email>
            </ContactsLine>
            <MainInfo>
              <Address>
                <span>Office:</span> Baarerstrasse 135, Zug, ZG 6300, Switzerland
              </Address>
              <ContactsLine>
                <Email href={'mailto:info@colizeum.io'} target={'_blank'}>
                  info@colizeum.io
                </Email>
                <TelegramWrap href={'https://t.me/colizeum'} target={'_blank'}>
                  <Telegram>
                    t.me/colizeum
                  </Telegram>
                  <IconWrap>
                    <TelegramIcon />
                  </IconWrap>
                </TelegramWrap>
              </ContactsLine>
            </MainInfo>
          </Info>
          <Form />
        </Content>
      </Container>
    </PageWrapper>
  </Root>
)

export default Contacts

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 4610px;
    height: 2103px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("${bg}") center no-repeat;
    ${props => props.theme.adaptive.index.xl} {
      width: 1713px;
      height: 943px;
      background-size: cover;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 1468px;
      height: 808px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 1269px;
      height: 699px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 1264px;
      height: 696px;
      background-size: cover;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 757px;
      height: 417px;
    }
    @media screen and (max-width: 800px) {
      content:  unset;
    }
  }
`

const Container = styled.div`
  position: relative;
  width: 1600px;
  margin: auto;
  ${props => props.theme.adaptive.index.xl} {
    width: 1400px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 1192px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 1024px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 1073px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 700px;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.045em;
  color: #353636;
  margin-bottom: 38px;
  text-transform: uppercase;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 53px;
    line-height: 67px;
    margin-bottom: 32px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 25px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 45px;
    line-height: 57px;
    margin-bottom: 22px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 22px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 0;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

const Info = styled.div`
  width: 445px;
  ${props => props.theme.adaptive.index.xl} {
    width: 374px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 321px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 307px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 276px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 235px;
  }
`

const Manager = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  margin-top: 32px;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 28px;
    margin-bottom: 21px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 24px;
    margin-bottom: 19px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 22px;
    margin-bottom: 18px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 54px;
    margin-bottom: 10px;
  }
`

const PhotoFrame = styled.div`
  position: relative;
  width: 235px;
  height: 165px;
  border: 3px solid #FFFFFF;
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F8F8F8 100%);
  margin-right: 29px;
  overflow: hidden;
  ${props => props.theme.adaptive.index.xl} {
    width: 206px;
    height: 144px;
    margin-right: 25px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 177px;
    height: 124px;
    margin-right: 21px;
    border: 2px solid #FFFFFF;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 164px;
    height: 115px;
    margin-right: 19px;
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 152px;
    height: 107px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 132px;
    height: 93px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 176px;
    height: 198px;
    top: 14px;
    right: -42px;
    background: url("${mask}") center no-repeat;
    background-size: cover;
    ${props => props.theme.adaptive.index.xl} {
      width: 154px;
      height: 173px;
      top: 12px;
      right: -37px;
    }
    ${props => props.theme.adaptive.index.lg} {
      width: 132px;
      height: 149px;
      top: 10px;
      right: -32px;
    }
    ${props => props.theme.adaptive.index.md} {
      width: 122px;
      height: 138px;
      top: 9px;
      right: -30px;
    }
    ${props => props.theme.adaptive.index.smd} {
      width: 113px;
      height: 129px;
      top: 9px;
      right: -27px;
    }
    ${props => props.theme.adaptive.index.sm} {
      width: 98px;
      height: 112px;
      top: 8px;
      right: -23px;
    }
  }
`

const Photo = styled.div`
  position: absolute;
  top: -16px;
  width: 189px;
  height: 189px;
  background: url("${photoManager}") center no-repeat;
  ${props => props.theme.adaptive.index.xl} {
    background-size: contain;
    top: -12px;
    width: 165px;
    height: 165px;
  }
  ${props => props.theme.adaptive.index.lg} {
    top: -10px;
    width: 142px;
    height: 142px;
  }
  ${props => props.theme.adaptive.index.md} {
    top: -8px;
    width: 131px;
    height: 131px;
  }
  ${props => props.theme.adaptive.index.smd} {
    top: -8px;
    width: 122px;
    height: 122px;
  }
  ${props => props.theme.adaptive.index.sm} {
    top: -8px;
    width: 107px;
    height: 107px;
  }
`

const ManagerInfo = styled.div`
  width: 155px;
  ${props => props.theme.adaptive.index.xl} {
    width: 139px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 119px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 110px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 105px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 93px;
  }
`

const ManagerName = styled.div`
  font-weight: 200;
  font-size: 42px;
  line-height: 51px;
  text-transform: uppercase;
  color: #353636;
  margin-bottom: 14px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 37px;
    line-height: 45px;
    margin-bottom: 12px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 9px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 28px;
    line-height: 33px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 8px;
  }
`

const ManagerPosition = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.05em;
  color: #353636;
  opacity: 0.5;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 17px;
    line-height: 23px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 14px;
    line-height: 19px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 13px;
    line-height: 17px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 16px;
  }
`

const ContactsLine = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Email = styled.a`
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #353636;
  margin-right: 47px;
  transition: 0.4s;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 28px;
    margin-right: 41px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 18px;
    line-height: 24px;
    margin-right: 35px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 17px;
    line-height: 23px;
    margin-right: 30px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 15px;
    line-height: 20px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
  }
  &:hover {
    color: #DF6DEF;
  }
`

const Telegram = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #353636;
  margin-right: 9px;
  transition: 0.4s;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 21px;
    line-height: 28px;
    margin-right: 8px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 18px;
    line-height: 24px;
    margin-right: 10px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 17px;
    line-height: 23px;
    margin-right: 4px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 15px;
    line-height: 20px;
    margin-right: 6px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 17px;
    margin-right: 5px;;
  }
`

const IconWrap = styled.div`
  width: 21px;
  height: 17px;
  ${props => props.theme.adaptive.index.xl} {
    width: 18px;
    height: 15px;
  }
  ${props => props.theme.adaptive.index.lg} {
    width: 15px;
    height: 13px;
  }
  ${props => props.theme.adaptive.index.md} {
    width: 14px;
    height: 12px;
  }
  ${props => props.theme.adaptive.index.smd} {
    width: 13px;
    height: 11px;
  }
  ${props => props.theme.adaptive.index.sm} {
    width: 11px;
    height: 10px;
  }
  svg {
    display: block;
    transition: 0.4s;
    width: 100%;
    height: auto;
    fill: #353636;
  }
`

const TelegramWrap = styled.a`
  display: flex;
  align-items: center;
  &:hover ${Telegram} {
    color: #DF6DEF;
  }
  &:hover ${IconWrap} svg{
    fill: #DF6DEF;
  }
`

const MainInfo = styled.div`
  margin-top: 100px;
  ${props => props.theme.adaptive.index.xl} {
    margin-top: 88px;
  }
  ${props => props.theme.adaptive.index.lg} {
    margin-top: 75px;
  }
  ${props => props.theme.adaptive.index.md} {
    margin-top: 68px;
  }
  ${props => props.theme.adaptive.index.smd} {
    margin-top: 65px;
  }
  ${props => props.theme.adaptive.index.sm} {
    margin-top: 33px;
  }
`

const Address = styled.div`
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #353636;
  margin-bottom: 9px;
  ${props => props.theme.adaptive.index.xl} {
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 8px;
  }
  ${props => props.theme.adaptive.index.lg} {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 7px;
  }
  ${props => props.theme.adaptive.index.md} {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 6px;
  }
  ${props => props.theme.adaptive.index.smd} {
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  ${props => props.theme.adaptive.index.sm} {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
  }
  span {
    color: #000000;
  }
`
