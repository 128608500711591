import React from "react";
import styled from 'styled-components'
import cardanoImg from "../../img/index/cardano-img.svg";
import titleImg from "../../img/index/title-img.svg";
import bgImg from '../../img/mobile/bg.svg'
import gameImg from "../../img/index/game-logo.svg";
import linkList from "../../store/downloadLinks";
import youtubeImg from "../../img/index/youtube-icon.svg";
import bigConor from '../../img/conor/big-conor.png'

interface LinkWProps {
  padding: number
}

const Mobile: React.FunctionComponent = () => (
  <Root>
    <Container>
      <Content>
        <TitleWrap>
          <Title src={titleImg} />
          <Built>
            Built for Cardano
          </Built>
        </TitleWrap>
        <Description>
          Turning Digital Funnel into attention-centric ecosystem
        </Description>
        <YoutubeWrap>
          <Conor />
          <YoutubeContainer>
            <YoutubeLink>
              <YoutubeIcon src={youtubeImg} />
            </YoutubeLink>
            <YoutubeText>
              Show is run by
            </YoutubeText>
            <YoutubeConor>
              Conor McGregor
            </YoutubeConor>
          </YoutubeContainer>
        </YoutubeWrap>
        <GamePanel>
          <GameLogo src={gameImg} />
          <DownloadLinks>
            {linkList.map((link, idx) => {
              return (
                <LinkWrap
                  padding={link.padding}
                  href={link.link}
                  target={'_blank'}
                >
                  <Link
                    height={link.height}
                  >
                    {link.getIcon()}
                  </Link>
                </LinkWrap>
              )
            })}
          </DownloadLinks>
        </GamePanel>
      </Content>
    </Container>
  </Root>
)

export default Mobile

const Root = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 21px 30px 0;
`

const Content = styled.div`
  position: relative;
  width: 260px;
  margin: 0 auto;
  &:before {
    content: '';
    position: absolute;
    width: 476px;
    height: 548px;
    left: -114px;
    top: 83px;
    background: url("${bgImg}") center no-repeat;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 224px;
  height: 62px;
`

const Built = styled.div`
  position: absolute;
  width: 92px;
  bottom: 0;
  right: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.05em;
  color: #424445;
  text-align: right;
  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 26px;
    top: -6px;
    right: -35px;
    background: url("${cardanoImg}") center no-repeat;
    background-size: cover;
  }
`


const Title = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
`

const Description = styled.div`
  width: 100%;
  margin-top: 26px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #353636;
  opacity: 0.7;
`

const GamePanel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 160px;
`

const GameLogo = styled.img`
  display: block;
  width: 117px;
  height: 27px;
`

const DownloadLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 11px;
`

const LinkWrap = styled.a<LinkWProps>`
  display: flex;
  align-items: center;
  width: 27px;
  height: 27px;
  margin-right: 7px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  padding: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  transition: 0.4s;
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }
  & .text {
    opacity: 0;
  }
`

interface LinkProps {
  height: string
}

const Link = styled.div<LinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  svg{
    display: block;
    height: auto;
    width: 66px;
  }
`

const YoutubeWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 29px;

`

const YoutubeContainer = styled.div`
  position: relative;
  width: 86px;
`

const YoutubeText = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.045em;
  color: #353636;
`

const YoutubeConor = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.045em;
  color: #353636;
`

const YoutubeLink = styled.a`
  display: block;
  position: absolute;
  right: 5px;
  top: 24px;
`

const YoutubeIcon = styled.img`
  display: block;
  width: 23px;
  height: 14px;
`

const Conor = styled.div`
  position: absolute;
  width: 357px;
  height: 357px;
  top: -24px;
  right: -29px;
  background: url("${bigConor}") center no-repeat;
  background-size: cover;
`
