import React from 'react'
import { Props } from './Product'
import Product from './Product'
import tournamentsIcon from 'img/modals/products/tournaments-icon.png'
import tournaments from 'img/modals/products/tournaments_1.svg'

function ProductTournament(props: Props) {
  const {
    active,
    onClose,
    onNext,
  } = props

  const modalData = {
    title: 'Tournaments',
    iconPath: tournamentsIcon,
    image: tournaments,
    tabs: ['Free Tournaments', 'Paid Tournaments'],
    text: ['The roles and process tokenomics:', ''],
    steps: [
        [
          '<strong>Tournament creator:</strong> originates the tournament and establishes the prize pool. The prize pool is ZEUM denominated and serves as an incentive for gamers to join the tournament. While they play for free, they do pay their attention in exchange.',
          '<strong>Gamers</strong> stake tokens at Colizeum in order to participate in the Free Tournaments. Winning players receive a set portion of the prize pool (~90% by default). Winning the tournament is not the only possible criteria to receive rewards: various other in-game achievements can be set to be eligible for ZEUM payouts.',
          '<strong>Colizeum:</strong> provides the platform where Tournament Creators, Game Devs, and Gamers collaborate; in return receives a percent of the total prize pool (~3% by default).',
          '<strong>Game Devs</strong> must stake tokens for their game to be eligible for hosting tournaments in. Stake can be delegated by the community or other interested parties. They also are eligible for the remaining ~3% of the prize pool.',
        ],
        [
          'Tournament creator sets up the tournament and the prize pool target size. Creating a tournament requires having ZEUM staked.',
          `
            <p>Gamers have to put up a ZEUM prize pool (that can be delegated) effectively depositing a fixed minimum contribution or a higher one.</p>
            <p>The amount contributed will define one's pro-rata allocation shall he or she be eligible (by e.g. winning the tournament). ~90% of the total prize pool is distributed back across the winning players (or likewise, those who have satisfied a more exotic criteria based on the in-game achievements).</p>
          `,
          'Colizeum receives ~3% of the Prize pool for providing the platform.',
          'Game Devs must stake tokens for their game to be eligible for hosting tournaments in. Stake can be delegated by the community or other interested parties. They also are eligible for the remaining ~3% of the prize pool.',
        ],
    ]
  }
  return (
    <Product
      type="tournaments"
      active={active}
      tabs={true}
      content={modalData}
      onClose={onClose}
      onNext={onNext}
    />
  )
}

export default ProductTournament
